"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { gql, useQuery, useSubscription } from "@/shared/hooks/gql";
import { sendNotification } from "@/pwa/notifications";
import { useEffect, useMemo, useRef, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { useMembers } from "./useMembers";
import { currentUserHook, useRoomId } from "kosmi-sdk/helpers";
import { useDebounce } from "@/uikit";
const ROOM_CHAT = gql(`
  query RoomChatQuery($roomId: String!, $channelId: String!, $cursor: String) {
    chatArchive(roomId: $roomId, channelId: $channelId, cursor: $cursor) {
      forwardCursor
      backCursor
      results {
        id
        user {
          id
          isAnonymous
          isSubscribed
          username
          displayName
          avatarUrl
        }
        member {
          id
          role
        }
        body
        time
      }
    }
    room(id: $roomId) {
      id
      state {
        members {
          id
        }
      }
    }
  }
`);
const READ_MESSAGES = gql(`
  query MessageReaders($roomId: String!) {
    messageReaders(roomId:$roomId) {
      time
      user {
        id
        displayName
        avatarUrl
      }
    }
  }
`);
export function clearReaders(client, roomId) {
  client.cache.writeQuery({
    query: READ_MESSAGES,
    variables: {
      roomId
    },
    data: {
      messageReaders: []
    }
  });
}
export function addMessage(client, roomId, userId, body, timestamp) {
  return __async(this, null, function* () {
    var _a, _b;
    const getExistingData = () => client.cache.readQuery({
      query: ROOM_CHAT,
      variables: {
        roomId,
        channelId: "general"
      }
    });
    const existingData = getExistingData();
    const existingMessageByCurrentUser = (_b = (_a = existingData == null ? void 0 : existingData.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _b.find((m) => {
      var _a2;
      return ((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) === userId;
    });
    if (existingData && existingData.chatArchive && existingMessageByCurrentUser) {
      const existingMessages = existingData.chatArchive.results;
      const newMessages = [...existingMessages || []];
      const id = `pending-${Math.random()})`;
      if (newMessages) {
        newMessages.push({
          id,
          user: existingMessageByCurrentUser.user,
          member: existingMessageByCurrentUser.member,
          body,
          time: timestamp
        });
      }
      client.cache.writeQuery({
        query: ROOM_CHAT,
        variables: {
          roomId,
          channelId: "general"
        },
        data: __spreadProps(__spreadValues({}, existingData), {
          chatArchive: __spreadProps(__spreadValues({}, existingData.chatArchive), {
            results: newMessages
          })
        })
      });
      const removeMessage = () => {
        var _a2;
        const existingData2 = getExistingData();
        const messages = (_a2 = existingData2 == null ? void 0 : existingData2.chatArchive) == null ? void 0 : _a2.results;
        if (!messages)
          return;
        client.cache.writeQuery({
          query: ROOM_CHAT,
          variables: {
            roomId,
            channelId: "general"
          },
          data: {
            chatArchive: {
              results: messages.filter((m) => (m == null ? void 0 : m.id) !== id)
            }
          }
        });
      };
      setTimeout(removeMessage, 2e3);
    }
  });
}
export function addReaderWithUnixTime(client, roomId, userId, displayName, avatarUrl) {
  const getServerTime = window.getServerTime;
  const serverTime = getServerTime();
  if (!roomId || !userId || !displayName || !avatarUrl)
    return;
  const currentTime = Math.floor(serverTime / 1e3);
  const existingData = client.cache.readQuery({
    query: READ_MESSAGES,
    variables: {
      roomId
    }
  });
  if (existingData && existingData.messageReaders) {
    const existingReader = existingData.messageReaders.find((reader) => {
      var _a;
      return ((_a = reader == null ? void 0 : reader.user) == null ? void 0 : _a.id) === userId;
    });
    if (!existingReader) {
      const newUser = {
        user: {
          __typename: "User",
          id: userId,
          displayName,
          avatarUrl
        },
        time: currentTime,
        __typename: "MessageReader"
      };
      client.cache.writeQuery({
        query: READ_MESSAGES,
        variables: {
          roomId
        },
        data: {
          messageReaders: [...existingData == null ? void 0 : existingData.messageReaders, newUser]
        }
      });
    }
  }
}
const USER_TYPING = gql(`
  subscription UserTyping($roomId: String!) {
    userTyping(roomId:$roomId) {
      user {
        id
        avatarUrl
        displayName
      }
    }
  }
`);
const NEW_MESSAGE = gql(`
  subscription NewMessageSubscription($roomId: String!, $channelId: String) {
    newMessage(roomId: $roomId, channelId: $channelId) {
      id
      user {
        id
        isAnonymous
        isSubscribed
        username
        displayName
        avatarUrl
      }
      member {
        id
        role
      }
      body
      time
    }
  }
`);
export const useUsersTyping = () => {
  var _a, _b;
  const getServerTime = window.getServerTime;
  const roomId = useRoomId();
  const currentUser = currentUserHook();
  const {
    data
  } = useSubscription(USER_TYPING, {
    variables: {
      roomId
    }
  });
  const _typingUser = ((_a = data == null ? void 0 : data.userTyping) == null ? void 0 : _a.user) || null;
  const {
    data: messagesData
  } = useMessagesCached(roomId, "general");
  const [typingUsers, setTypingUsers] = useState([]);
  const debouncedSetTypingUsers = useDebounce(setTypingUsers, 300, {
    leading: true
  });
  const messages = ((_b = messagesData == null ? void 0 : messagesData.chatArchive) == null ? void 0 : _b.results) || [];
  const latestTimestampsByTypingUsers = typingUsers.reduce((acc, typingUser) => {
    var _a2;
    const messagesByTypingUsers = messages.filter((message) => typingUsers.some((typingUser2) => {
      var _a3;
      return typingUser2.user.id === ((_a3 = message == null ? void 0 : message.user) == null ? void 0 : _a3.id);
    }));
    const userMessages = messagesByTypingUsers.filter((message) => {
      var _a3, _b2;
      return ((_a3 = message == null ? void 0 : message.user) == null ? void 0 : _a3.id) === ((_b2 = typingUser == null ? void 0 : typingUser.user) == null ? void 0 : _b2.id);
    });
    const latestMessage = userMessages.reduce((latest, current) => {
      if (!(current == null ? void 0 : current.time))
        return latest;
      if (!(latest == null ? void 0 : latest.time))
        return current;
      return (current == null ? void 0 : current.time) > (latest == null ? void 0 : latest.time) ? current : latest;
    }, userMessages[0]);
    if (latestMessage == null ? void 0 : latestMessage.time) {
      acc[(_a2 = typingUser == null ? void 0 : typingUser.user) == null ? void 0 : _a2.id] = latestMessage.time * 1e3;
    }
    return acc;
  }, {});
  useEffect(() => {
    if (!_typingUser)
      return;
    const typingUser = {
      id: (_typingUser == null ? void 0 : _typingUser.id) || "",
      displayName: (_typingUser == null ? void 0 : _typingUser.displayName) || "",
      avatarUrl: (_typingUser == null ? void 0 : _typingUser.avatarUrl) || ""
    };
    debouncedSetTypingUsers((typingUsers2) => {
      const now = getServerTime();
      const newTypingUsers = typingUsers2.filter((u) => u.user.id !== typingUser.id);
      if ((typingUser == null ? void 0 : typingUser.avatarUrl) && (typingUser == null ? void 0 : typingUser.displayName)) {
        newTypingUsers.push({
          user: {
            id: typingUser.id,
            avatarUrl: typingUser.avatarUrl,
            displayName: typingUser.displayName
          },
          time: now
        });
      }
      newTypingUsers.sort((a, b) => a.user.id < b.user.id ? -1 : 1);
      return newTypingUsers;
    });
    const interval = setInterval(() => {
      const now = getServerTime();
      debouncedSetTypingUsers((typingUsers2) => typingUsers2.filter((u) => now - u.time < 2e3));
    }, 2e3);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [_typingUser, currentUser == null ? void 0 : currentUser.id, getServerTime, debouncedSetTypingUsers]);
  const results = useMemo(() => typingUsers.filter((u) => {
    if (u.user.id === (currentUser == null ? void 0 : currentUser.id))
      return false;
    const latestTimestamp = latestTimestampsByTypingUsers[u.user.id] || 0;
    return latestTimestamp + 1e3 < u.time;
  }).map((u) => u.user), [typingUsers, currentUser == null ? void 0 : currentUser.id, latestTimestampsByTypingUsers]);
  return results;
};
const MESSAGE_DELETED = gql(`
  subscription MessageDeletedSubscription($roomId: String!) {
    messageDeleted(roomId: $roomId) {
      messageId
    }
  }
`);
const ON_CLEAR_MESSAGES = gql(`
  subscription ClearMessagesSubscription($roomId: String!) {
    clearMessages(roomId: $roomId, channelId: "general") {
      ok
    }
  }
`);
export const useMessagesRead = (roomId) => {
  const members = useMembers();
  const nMembers = (members == null ? void 0 : members.length) || 0;
  const {
    data
  } = useQuery(READ_MESSAGES, {
    variables: {
      roomId
    },
    skip: nMembers > 20
  });
  if (nMembers > 20) {
    return [];
  }
  return (data == null ? void 0 : data.messageReaders) || [];
};
export const useMessagesCached = (roomId, channelId) => {
  const {
    data,
    fetchMore,
    loading,
    networkStatus
  } = useQuery(ROOM_CHAT, {
    variables: {
      roomId,
      channelId
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true
  });
  const nextPage = () => __async(void 0, null, function* () {
    if (loading)
      return;
    const chatArchive = (data == null ? void 0 : data.chatArchive) || {};
    if (!chatArchive.forwardCursor)
      return;
    yield fetchMore({
      variables: {
        roomId,
        channelId,
        cursor: chatArchive.forwardCursor
      },
      updateQuery: (prev, {
        fetchMoreResult
      }) => {
        var _a, _b, _c, _d, _e;
        if (!fetchMoreResult)
          return prev;
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev == null ? void 0 : prev.chatArchive), {
            results: (_c = (_a = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _c.concat(((_b = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _b.results) || []),
            forwardCursor: (_d = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _d.forwardCursor,
            backCursor: (_e = fetchMoreResult == null ? void 0 : fetchMoreResult.chatArchive) == null ? void 0 : _e.forwardCursor
          })
        });
      }
    });
  });
  return {
    data,
    nextPage,
    loading: !data || networkStatus === 3
  };
};
const useMembersRef = () => {
  const members = useMembers();
  const membersRef = useRef(members);
  useEffect(() => {
    membersRef.current = members;
  }, [members]);
  return membersRef;
};
export default (roomId, channelId) => {
  const client = useApolloClient();
  const membersRef = useMembersRef();
  const currentUser = currentUserHook();
  const {
    subscribeToMore,
    data,
    loading
  } = useQuery(ROOM_CHAT, {
    variables: {
      roomId,
      channelId
    },
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    const unsubscribeToMessages = subscribeToMore({
      document: NEW_MESSAGE,
      variables: {
        roomId,
        channelId
      },
      updateQuery: (prev, data2) => {
        var _a, _b, _c, _d;
        const {
          subscriptionData
        } = data2;
        const MAX_MESSAGES = 100;
        const message = subscriptionData == null ? void 0 : subscriptionData.data.newMessage;
        const prevResults = ((_a = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _a.results) || [];
        const messageExists = !!prevResults.find((m) => (m == null ? void 0 : m.id) === (message == null ? void 0 : message.id));
        const pendingMessage = prevResults.filter((m) => {
          var _a2;
          return (_a2 = m == null ? void 0 : m.id) == null ? void 0 : _a2.startsWith("pending-");
        }).shift();
        if (messageExists)
          return prev;
        let updated_messages = messageExists || !message ? prevResults : [...prevResults, message];
        if (pendingMessage && ((_b = message == null ? void 0 : message.user) == null ? void 0 : _b.id) === (currentUser == null ? void 0 : currentUser.id)) {
          updated_messages = updated_messages.filter((m) => (m == null ? void 0 : m.id) !== (pendingMessage == null ? void 0 : pendingMessage.id));
        }
        clearReaders(client, roomId);
        (_c = membersRef == null ? void 0 : membersRef.current) == null ? void 0 : _c.forEach((m) => {
          var _a2, _b2, _c2, _d2, _e, _f, _g;
          if (((_a2 = m == null ? void 0 : m.user) == null ? void 0 : _a2.id) !== ((_b2 = message == null ? void 0 : message.user) == null ? void 0 : _b2.id) && (currentUser == null ? void 0 : currentUser.id) !== ((_c2 = m == null ? void 0 : m.user) == null ? void 0 : _c2.id) && !((_d2 = m == null ? void 0 : m.user) == null ? void 0 : _d2.isAnonymous)) {
            addReaderWithUnixTime(client, roomId, (_e = m == null ? void 0 : m.user) == null ? void 0 : _e.id, (_f = m == null ? void 0 : m.user) == null ? void 0 : _f.displayName, (_g = m == null ? void 0 : m.user) == null ? void 0 : _g.avatarUrl);
          }
        });
        if (message && document.hidden && roomId !== "lobby" && !roomId.endsWith("/lobby")) {
          sendNotification((message == null ? void 0 : message.id) || "", "Message from " + ((_d = message == null ? void 0 : message.user) == null ? void 0 : _d.displayName), (message == null ? void 0 : message.body) || "");
        }
        if (prevResults.length <= MAX_MESSAGES && updated_messages.length > MAX_MESSAGES) {
          updated_messages = updated_messages.slice(Math.max(updated_messages.length - MAX_MESSAGES, 0));
        }
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: updated_messages
          })
        });
      }
    });
    const unsubscribeToMessageDeletion = subscribeToMore({
      document: MESSAGE_DELETED,
      variables: {
        roomId
      },
      updateQuery: (prev, {
        subscriptionData
      }) => {
        var _a, _b;
        const {
          messageDeleted
        } = subscriptionData.data;
        if (!messageDeleted)
          return prev;
        const {
          messageId
        } = messageDeleted;
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: (_b = (_a = prev == null ? void 0 : prev.chatArchive) == null ? void 0 : _a.results) == null ? void 0 : _b.filter((m) => (m == null ? void 0 : m.id) !== messageId)
          })
        });
      }
    });
    const unsubscribeToClearMessages = subscribeToMore({
      document: ON_CLEAR_MESSAGES,
      variables: {
        roomId
      },
      updateQuery: (prev) => {
        return __spreadProps(__spreadValues({}, prev), {
          chatArchive: __spreadProps(__spreadValues({}, prev.chatArchive), {
            results: []
          })
        });
      }
    });
    return () => {
      unsubscribeToClearMessages();
      unsubscribeToMessageDeletion();
      unsubscribeToMessages();
    };
  }, [subscribeToMore, roomId, channelId, client, membersRef, currentUser == null ? void 0 : currentUser.id]);
  return {
    loading,
    data
  };
};
