"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { memo, useCallback, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Button, Settings } from "@/uikit";
import withDialogs from "../hooks/withDialogs";
import useEnablePushNotifications, { useCheckPermissions } from "@/shared/hooks/useEnablePushNotifications";
import { sendNotification } from "@/pwa/notifications";
import { gql, useMutation } from "@/shared/hooks/gql";
const EnableNotifications = withDialogs(({
  alert
}) => {
  const [enabled, setEnabled] = useState(false);
  const enablePushNotifications = useEnablePushNotifications();
  const checkPermissions = useCheckPermissions();
  const onClick = useCallback(() => __async(void 0, null, function* () {
    if (enabled && alert) {
      alert("Notifications are already enabled. If you want to disable them you'll need to configure your browser or app settings.");
      return;
    }
    if (!alert)
      return;
    const result = yield enablePushNotifications();
    setEnabled(result === "granted");
    if (result !== "granted")
      alert("Failed to enable notifications. Please enable them in your browser or app options.");
  }), [enablePushNotifications, alert, enabled]);
  useEffect(() => {
    ;
    (() => __async(void 0, null, function* () {
      setEnabled((yield checkPermissions()) === "granted");
    }))();
  }, [checkPermissions]);
  return /* @__PURE__ */ React.createElement(Settings.Checkbox, { label: "Enable Notifications", setChecked: onClick, checked: enabled });
});
const TEST_PUSH_NOTIFICATION = gql(`
  mutation TestNotification {
    testNotification {
      ok
    }
  }
`);
const useTestPushNotification = () => {
  const [sendTestNotification] = useMutation(TEST_PUSH_NOTIFICATION);
  return useCallback(() => sendTestNotification(), [sendTestNotification]);
};
const DebugSection = memo(() => {
  const sendTestNotification = useTestPushNotification();
  return /* @__PURE__ */ React.createElement(Settings.Container, { name: "Debug Notifications" }, !Capacitor.isNativePlatform() && /* @__PURE__ */ React.createElement(Settings.Button, { name: "Send Local Notification", onClick: () => {
    sendNotification(Date.now().toString(), "Test title " + Date.now().toString(), "Test message " + Date.now().toString());
  } }), /* @__PURE__ */ React.createElement(Settings.Button, { name: "Send Push Notification", onClick: sendTestNotification }));
});
export default memo(() => {
  return /* @__PURE__ */ React.createElement(Settings.Section, { name: "Notifications", icon: "bell outline" }, /* @__PURE__ */ React.createElement(Settings.Container, { name: "Notifications" }, /* @__PURE__ */ React.createElement(Settings.InputGroup, null, /* @__PURE__ */ React.createElement(EnableNotifications, null)), /* @__PURE__ */ React.createElement(Settings.Section, { name: "Debug Notifications", icon: "bug", trigger: /* @__PURE__ */ React.createElement(Button, { icon: "bug", color: "blue", style: {
    marginTop: 10,
    margin: "auto"
  } }, "Debug Notifications") }, /* @__PURE__ */ React.createElement(DebugSection, null))));
});
