"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useEffect, useState, useCallback, memo } from "react";
import ReactPlayer from "react-player";
import { Html } from "@/uikit";
import { canPlay, HLS_EXTENSIONS, DASH_EXTENSIONS } from "react-player/lib/patterns";
const MATCH_CLOUDFLARE_STREAM = /https:\/\/watch\.cloudflarestream\.com\/([a-z0-9]+)/;
function shouldUseHLS(url) {
  return HLS_EXTENSIONS.test(url) || MATCH_CLOUDFLARE_STREAM.test(url);
}
function shouldUseDASH(url) {
  return DASH_EXTENSIONS.test(url);
}
export default memo((props) => {
  const useHackedPlayer = canPlay.file(props.url) && !shouldUseHLS(props.url) && !shouldUseDASH(props.url);
  if (useHackedPlayer) {
    return /* @__PURE__ */ React.createElement(VideoWrapper, __spreadValues({}, props));
  }
  return /* @__PURE__ */ React.createElement(ReactPlayer, __spreadValues({}, props));
});
const VideoWrapper = memo((props) => {
  var _a, _b;
  const attributes = ((_b = (_a = props == null ? void 0 : props.config) == null ? void 0 : _a.file) == null ? void 0 : _b.attributes) || {};
  const [videoElement, setVideoElement] = useState(null);
  useEffect(() => {
    if (!videoElement)
      return;
    if (!props.playing) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
  }, [props.playing, videoElement]);
  useEffect(() => {
    if (!videoElement)
      return;
    if (props.volume || props.volume === 0) {
      videoElement.volume = props.volume;
    }
  }, [props.volume, videoElement]);
  const propsOnProgress = props.onProgress;
  const onProgress = useCallback((e) => {
    const target = e.target;
    if (!propsOnProgress)
      return;
    propsOnProgress({
      playedSeconds: target.currentTime,
      loadedSeconds: target.currentTime,
      played: target.currentTime / target.duration,
      loaded: target.currentTime / target.duration
    });
  }, [propsOnProgress]);
  const propsOnDuration = props.onDuration;
  const onDuration = useCallback((e) => {
    const target = e.target;
    if (!propsOnDuration)
      return;
    propsOnDuration(target.duration);
  }, [propsOnDuration]);
  useEffect(() => {
    if (!videoElement)
      return;
    videoElement.addEventListener("error", props.onError);
    videoElement.addEventListener("play", props.onPlay);
    videoElement.addEventListener("pause", props.onPause);
    videoElement.addEventListener("ended", props.onEnded);
    videoElement.addEventListener("timeupdate", onProgress);
    videoElement.addEventListener("loadedmetadata", onDuration);
    return () => {
      videoElement.removeEventListener("error", props.onError);
      videoElement.removeEventListener("play", props.onPlay);
      videoElement.removeEventListener("pause", props.onPause);
      videoElement.removeEventListener("ended", props.onEnded);
      videoElement.removeEventListener("timeupdate", onProgress);
      videoElement.removeEventListener("loadedmetadata", onDuration);
    };
  }, [props.onError, props.onPause, props.onPlay, props.onEnded, onProgress, onDuration, videoElement]);
  const onReady = props.onReady;
  const propsUrl = props.url;
  useEffect(() => {
    if (videoElement && onReady) {
      onReady({
        _reactInternals: {
          key: propsUrl
        },
        getInternalPlayer: (type) => !type ? videoElement : {},
        getCurrentTime: () => videoElement.currentTime,
        getDuration: () => videoElement.duration,
        seekTo: (position) => {
          videoElement.currentTime = position;
        }
      });
    }
  }, [videoElement, onReady, propsUrl]);
  return /* @__PURE__ */ React.createElement("div", { className: props.className }, /* @__PURE__ */ React.createElement(Html.video, __spreadValues({ ref: setVideoElement, styleProps: {
    width: props.width,
    height: props.height
  }, src: props.url, controls: props.controls, muted: props.muted, playsInline: props.playsinline }, attributes), props.children));
});
