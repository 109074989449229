"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ColorSelector = exports.AppearanceSettings = exports.AppSettings = exports.AccountSection = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _platform = _interopRequireDefault(require("platform"));
var _gql = require("@/shared/hooks/gql");
var _colors = require("@/__generated__/colors");
var _useSettings = _interopRequireDefault(require("@/shared/hooks/useSettings"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _LogoutButton = _interopRequireDefault(require("@/shared/components/LogoutButton"));
var _Premium = require("@/shared/components/Premium");
var _useColorSchemes = _interopRequireDefault(require("@/shared/hooks/useColorSchemes"));
var _helpers = require("kosmi-sdk/helpers");
var _MediaSourceSettings = _interopRequireDefault(require("@/shared/components/MediaSourceSettings"));
var _NotificationSettings = _interopRequireDefault(require("./NotificationSettings"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  hidden: {
    "Settings__styles.hidden": "Settings__styles.hidden",
    visibility: "xlshs6z",
    $$css: true
  },
  diamond: {
    "Settings__styles.diamond": "Settings__styles.diamond",
    marginLeft: "x1swdo50",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginTop: "x1gslohp",
    fontSize: "x1j61zf2",
    $$css: true
  },
  withBackgroundColor: backgroundColor => [{
    "Settings__styles.withBackgroundColor": "Settings__styles.withBackgroundColor",
    backgroundColor: "xq1mx2j",
    $$css: true
  }, {
    "--backgroundColor": backgroundColor != null ? backgroundColor : "initial"
  }],
  you: {
    "Settings__styles.you": "Settings__styles.you",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    $$css: true
  },
  codeMsg: {
    "Settings__styles.codeMsg": "Settings__styles.codeMsg",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    textAlign: "x2b8uid",
    $$css: true
  },
  confirmedText: {
    "Settings__styles.confirmedText": "Settings__styles.confirmedText",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    textAlign: "x2b8uid",
    color: "x16cd2qt",
    fontWeight: "x1xlr1w8",
    $$css: true
  },
  notConfirmedText: {
    "Settings__styles.notConfirmedText": "Settings__styles.notConfirmedText",
    color: "x1ko3i6n",
    $$css: true
  },
  link: {
    "Settings__styles.link": "Settings__styles.link",
    display: "xt0psk2",
    color: "x16cd2qt",
    padding: "xfawy5m",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    cursor: "x1ypdohk",
    fontWeight: "x1xlr1w8",
    $$css: true
  },
  avatar: {
    "Settings__styles.avatar": "Settings__styles.avatar",
    width: "x14atkfc",
    height: "x1vd4hg5",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxWidth: "x193iq5w",
    boxShadow: "xy51mgb",
    $$css: true
  },
  warning: {
    "Settings__styles.warning": "Settings__styles.warning",
    margin: "x1a7442q",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    textAlign: "x2b8uid",
    $$css: true
  },
  palettes: {
    "Settings__styles.palettes": "Settings__styles.palettes",
    display: "x78zum5",
    height: "xg7h5cd",
    width: "xh8yej3",
    maxWidth: "x193iq5w",
    flexWrap: "x1a02dak",
    gap: "x883omv",
    rowGap: null,
    columnGap: null,
    justifyContent: "xl56j7k",
    ":hover_cursor": "x1277o0a",
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  active: {
    "Settings__styles.active": "Settings__styles.active",
    fontWeight: "x1s688f",
    opacity: "x1hc1fzr",
    $$css: true
  },
  paletteWrapper: {
    "Settings__styles.paletteWrapper": "Settings__styles.paletteWrapper",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xq1glr1",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "xg7h5cd",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    opacity: "x1us6l5c",
    position: "x1n2onr6",
    transition: "x11fwqrk",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexDirection: "xdt5ytf",
    alignItems: "x1cy8zhl",
    width: "x1ozfbi4",
    $$css: true
  },
  palette: {
    "Settings__styles.palette": "Settings__styles.palette",
    display: "x78zum5",
    height: "xlrawln",
    width: "xygnafs",
    borderRadius: "x1q4ynmn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    marginTop: "x1anpbxc",
    $$css: true
  },
  color: {
    "Settings__styles.color": "Settings__styles.color",
    display: "x78zum5",
    height: "x5yr21d",
    width: "x152qxlz",
    margin: "xmoyep7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    $$css: true
  },
  profileHeader: {
    "Settings__styles.profileHeader": "Settings__styles.profileHeader",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: "x14vqqas",
    marginBottom: "xod5an3",
    padding: "xc7ga6q",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    maxWidth: "x27kpxv",
    alignItems: "x6s0dn4",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    justifyContent: "x1nhvcw1",
    lineHeight: "x1u7k74",
    "@media (max-width: 1000px)_marginTop": "x1exe3gt",
    "@media (max-width: 1000px)_marginBottom": "xo40yla",
    "@media (max-width: 1000px)_paddingLeft": "x1lons2e",
    "@media (max-width: 1000px)_paddingInlineStart": null,
    "@media (max-width: 1000px)_paddingInlineEnd": null,
    "@media (max-width: 1000px)_paddingRight": "x1lilav2",
    $$css: true
  },
  appVersion: {
    "Settings__styles.appVersion": "Settings__styles.appVersion",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "x1j61zf2",
    display: "x78zum5",
    $$css: true
  }
};
const SET_COLOR_SCHEME = (0, _gql.gql)(`
  mutation setColorScheme($colorScheme: String!) {
    updateSettings(colorScheme: $colorScheme) {
      ok
    }
  }
`);
const HIDE_COUNTRY = (0, _gql.gql)(`
  mutation setHideCountry($hideCountry: Boolean!) {
    updateSettings(hideCountry: $hideCountry) {
      ok
    }
  }
`);
const UPDATE_PROFILE = (0, _gql.gql)(`
  mutation UpdateProfileMutation22(
    $displayName: String
    $avatarFilename: String
    $password: String
    $email: String
    $emailCode: String
  ) {
    updateProfile(
      displayName: $displayName
      avatarFilename: $avatarFilename
      password: $password
      email: $email
      emailCode: $emailCode
    ) {
      id
      username
      displayName
      avatarUrl
      isAnonymous
      email
      emailConfirmed
    }
  }
`);
const SEND_CONFIRM_EMAIL_CODE = (0, _gql.gql)(`
  mutation SendConfirmEmailCode {
    sendConfirmEmailCode {
      ok
    }
  }
`);
const DELETE_ALL_MESSAGES = (0, _gql.gql)(`
  mutation DeleteAllMyMessages {
    deleteAllMessages {
      ok
    }
  }
`);
const DELETE_ACCOUNT = (0, _gql.gql)(`
  mutation DeleteAccount {
    destroyAccount {
      ok
    }
  }
`);
const CONTACT_SUPPORT = (0, _gql.gql)(`
  mutation SendSupportEmail($body: String!) {
    sendSupportEmail(body: $body) {
      ok
    }
  }
`);
const DataDeletionScreen = (0, _helpers.withDialogs)(({
  confirm,
  prompt
}) => {
  const [deleteAllMessages] = (0, _gql.useMutation)(DELETE_ALL_MESSAGES);
  const [destroyAccount] = (0, _gql.useMutation)(DELETE_ACCOUNT);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.warning
  }, "Selecting either option below will irrevocably erase your pertinent data from our infrastructure. This operation is non-reversible. Upon deletion of your account or messages, they are permanently eliminated, rendering them inaccessible to both you and Kosmi."), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "danger",
    onClick: () => __async(void 0, null, function* () {
      if (confirm && (yield confirm("Are you sure you want to permanently delete all your messages?"))) yield deleteAllMessages();
    }),
    name: "Delete all my messages"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Button, {
    color: "danger",
    name: "Delete my Account",
    onClick: () => __async(void 0, null, function* () {
      if ((prompt && (yield prompt("Are you sure you want to permanently delete your account if so type 'delete'?"))) === "delete") {
        yield destroyAccount();
        window.location.reload();
      }
    })
  }));
});
const AccountFields = (0, _helpers.withDialogs)(({
  confirm,
  alert,
  dark
}) => {
  const currentUser = (0, _helpers.currentUserHook)();
  const [displayName, setDisplayName] = _react.default.useState(currentUser.displayName);
  const [email, setEmail] = _react.default.useState(currentUser.email);
  const [emailCode, setEmailCode] = _react.default.useState("");
  const [password, setPassword] = _react.default.useState("");
  const [passwordConfirm, setPasswordConfirm] = _react.default.useState("");
  const [updateProfile] = (0, _gql.useMutation)(UPDATE_PROFILE);
  const [sendEmailCode] = (0, _gql.useMutation)(SEND_CONFIRM_EMAIL_CODE);
  const [avatarFile, setAvatarFile] = _react.default.useState(null);
  const updateDisplayName = () => __async(void 0, null, function* () {
    if (displayName === currentUser.displayName) return true;
    try {
      yield updateProfile({
        variables: {
          displayName
        }
      });
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
    }
    return true;
  });
  const updateEmail = () => __async(void 0, null, function* () {
    try {
      if (emailCode) {
        yield updateProfile({
          variables: {
            emailCode
          }
        });
      }
      if (email !== currentUser.email) {
        yield updateProfile({
          variables: {
            email
          }
        });
        alert && alert("Email Updated. We've sent you a code for confirmation.");
      } else if (currentUser.emailConfirmed) {
        return true;
      }
      return false;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updatePassword = () => __async(void 0, null, function* () {
    try {
      if (!password) return true;
      if (password !== passwordConfirm) {
        throw new Error("Passwords do not match");
      }
      yield updateProfile({
        variables: {
          password
        }
      });
      alert && alert("Password changed");
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  const updateAvatar = () => __async(void 0, null, function* () {
    try {
      if (!avatarFile) return true;
      const data = new FormData();
      data.append("file", avatarFile);
      const response = yield fetch("https://img.kosmi.io/", {
        method: "POST",
        body: data
      });
      const response_json = yield response.json();
      const avatarFilename = response_json.filename;
      yield updateProfile({
        variables: {
          avatarFilename
        }
      });
      return true;
    } catch (e) {
      alert && alert((e == null ? void 0 : e.message) || "Unexpected error");
      return false;
    }
  });
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Avatar",
    avatarUrl: currentUser.avatarUrl,
    onSave: updateAvatar,
    icon: "user circle"
  }, /* @__PURE__ */_react.default.createElement(_uikit.ImageUploader, {
    circular: true,
    className: styles.avatar,
    src: currentUser.avatarUrl,
    onFile: f => setAvatarFile(f)
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Display name",
    onSave: updateDisplayName,
    value: currentUser.displayName,
    icon: "address card",
    onBack: () => {
      setDisplayName(currentUser.displayName);
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    setValue: setDisplayName,
    name: "Display name",
    value: displayName
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Email",
    value: currentUser.email,
    onSave: updateEmail,
    icon: "envelope",
    onBack: () => {
      setEmail(currentUser.email);
      setEmailCode("");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    setValue: setEmail,
    name: "Email",
    value: email,
    label: "Email"
  }), currentUser.emailConfirmed ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.confirmedText
  }, "Email is confirmed") : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.confirmedText, styles.notConfirmedText]
  }, "You need to confirm your email"), !currentUser.emailConfirmed && email === currentUser.email && /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    name: "Email code",
    value: emailCode,
    label: "Email code",
    setValue: setEmailCode
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.codeMsg
  }, "Didn't get the code?", /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
    style: styles.link,
    onClick: () => __async(void 0, null, function* () {
      if (confirm && (yield confirm("Resend your email confirmation code?"))) {
        sendEmailCode();
      }
    })
  }, "Click here"), "to resend it"))), !_Premium.hidePremium && /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    icon: "kosmi diamond",
    name: "Kosmi Premium"
  }, /* @__PURE__ */_react.default.createElement(_Premium.PremiumScreen, {
    source: "MobileSettings",
    dark
  })), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Password",
    value: "********",
    onSave: updatePassword,
    icon: "lock",
    onBack: () => {
      setPassword("");
      setPasswordConfirm("");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    setValue: setPassword,
    name: "Password",
    value: password,
    label: "Password",
    type: "password"
  }), password && /* @__PURE__ */_react.default.createElement(_uikit.Settings.Input, {
    setValue: setPasswordConfirm,
    name: "Password confirm",
    value: passwordConfirm,
    label: "Password confirm",
    type: "password",
    autoFocus: false
  })), /* @__PURE__ */_react.default.createElement(_LogoutButton.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "white",
      inverted: true,
      style: {
        marginTop: 40,
        marginBottom: 40,
        marginLeft: "auto",
        marginRight: "auto",
        width: 148,
        height: 52
      },
      size: "l"
    }, "Log out")
  }), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Data Deletion",
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      color: "red",
      inverted: true,
      style: {
        marginTop: 40,
        marginBottom: 40,
        marginLeft: "auto",
        marginRight: "auto"
      }
    }, "Data deletion")
  }, /* @__PURE__ */_react.default.createElement(DataDeletionScreen, null)));
});
const AccountSection = ({
  dark
}) => {
  const isRoot = _uikit.Settings.useSettingsRouter().isRoot;
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Account",
    dark
  }, isRoot && /* @__PURE__ */_react.default.createElement(ProfileHeader, null), /* @__PURE__ */_react.default.createElement(AccountFields, {
    dark
  }));
};
exports.AccountSection = AccountSection;
const ContactSupportSection = (0, _helpers.withDialogs)(({
  alert
}) => {
  const [message, setMessage] = (0, _react.useState)("");
  const [sendSupportEmail] = (0, _gql.useMutation)(CONTACT_SUPPORT);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Contact Support",
    icon: "comment outline",
    onSave: () => __async(void 0, null, function* () {
      if (!message || message.split(" ").length < 5) {
        alert && alert("Please enter a message with at least 5 words");
        return false;
      }
      try {
        yield sendSupportEmail({
          variables: {
            body: `${message}

Sent from ${_platform.default.description}
              `
          }
        });
        return true;
      } catch (e) {
        if (alert) alert((e == null ? void 0 : e.message) || "Unexpected error");
      }
      return false;
    }),
    emphasizeSave: true,
    saveText: "Send"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.TextArea, {
    name: "What's on your mind?",
    value: message,
    setValue: setMessage
  }));
});
const ColorScheme = ({
  onClick,
  primaryColor,
  secondaryColor,
  foregroundColor
}) => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    color: "blue",
    style: styles.palette,
    onClick
  }, primaryColor && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.color, styles.withBackgroundColor(primaryColor)]
  }), secondaryColor && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.color, styles.withBackgroundColor(secondaryColor)]
  }), foregroundColor && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.color, styles.withBackgroundColor(foregroundColor)]
  }));
};
const ColorSelector = exports.ColorSelector = (0, _helpers.withDialogs)(({
  alert,
  selectedColor,
  setColorScheme,
  includeEmpty
}) => {
  const currentUser = (0, _helpers.currentUserHook)();
  let colorSchemes = (0, _useColorSchemes.default)();
  if (!colorSchemes.length) {
    return /* @__PURE__ */_react.default.createElement(_uikit.Loader, null);
  }
  colorSchemes = [{
    id: "default",
    name: "Kosmi",
    primaryColor: _colors.colors["primary"],
    secondaryColor: _colors.colors["secondary"],
    foregroundColor: _colors.colors["foreground"]
  }, ...colorSchemes];
  if (includeEmpty) {
    colorSchemes = [{
      id: null,
      name: "User Default",
      primaryColor: "",
      secondaryColor: "",
      foregroundColor: ""
    }, ...colorSchemes];
  }
  const freeThreshold = includeEmpty ? 4 : 3;
  if (_Premium.hidePremium && !currentUser.isSubscribed) {
    colorSchemes = colorSchemes.filter((_s, i) => i <= freeThreshold);
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.palettes
  }, colorSchemes.map((s, i) => /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    key: i,
    color: (s == null ? void 0 : s.id) === selectedColor ? "blue" : "var(--primary-dark-15)",
    onClick: () => {
      if (i > freeThreshold && !currentUser.isSubscribed) {
        if (alert) {
          alert(/* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
            message: "Please get Premium to get access to all the Kosmi color schemes!",
            source: "App colorschemes"
          }), true);
        }
      } else {
        setColorScheme(s == null ? void 0 : s.id);
      }
    },
    className: __spreadValues(__spreadValues({}, (s == null ? void 0 : s.id) === selectedColor ? styles.active : {}), styles.paletteWrapper)
  }, s == null ? void 0 : s.name, /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: [styles.diamond, (i < freeThreshold || currentUser.isSubscribed) && styles.hidden]
  }), /* @__PURE__ */_react.default.createElement(ColorScheme, {
    primaryColor: s == null ? void 0 : s.primaryColor,
    secondaryColor: s == null ? void 0 : s.secondaryColor,
    foregroundColor: s == null ? void 0 : s.foregroundColor
  }))));
});
const AppearanceSettings = () => {
  const settings = (0, _useSettings.default)();
  const [_setColorScheme] = (0, _gql.useMutation)(SET_COLOR_SCHEME);
  const setColorScheme = colorScheme => {
    if (!colorScheme) {
      return;
    }
    _setColorScheme({
      variables: {
        colorScheme
      }
    });
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(ColorSelector, {
    selectedColor: settings.colorScheme,
    setColorScheme
  }));
};
exports.AppearanceSettings = AppearanceSettings;
const AppearanceSection = () => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Color Scheme",
    icon: "paint brush"
  }, /* @__PURE__ */_react.default.createElement(AppearanceSettings, null));
};
const MicWebcamSection = () => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Mic/Webcam Settings",
    icon: "video camera"
  }, /* @__PURE__ */_react.default.createElement(_MediaSourceSettings.default, null));
};
const HideMyCountrySection = (0, _helpers.withDialogs)(({
  alert
}) => {
  const settings = (0, _useSettings.default)();
  const currentUser = (0, _helpers.currentUserHook)();
  const isSubscribed = currentUser.isSubscribed;
  const [_hideCountry] = (0, _gql.useMutation)(HIDE_COUNTRY);
  const setHideCountry = val => {
    return _hideCountry({
      variables: {
        hideCountry: val
      }
    });
  };
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Hide my country",
    value: settings.hideCountry ? "Enabled" : "Disabled",
    icon: "flag outline"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.InputGroup, null, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Checkbox, {
    setChecked: val => __async(void 0, null, function* () {
      if (!isSubscribed) {
        alert && alert(/* @__PURE__ */_react.default.createElement(_Premium.PaywallMessage, {
          message: "Please get Premium to hide your country!",
          source: "Hide country"
        }), true);
        return;
      }
      setHideCountry(val);
    }),
    checked: !!settings.hideCountry,
    label: "Hide my country in rooms"
  })));
});
const AppSettings = ({
  dark
}) => /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
  name: "App",
  dark
}, /* @__PURE__ */_react.default.createElement(AppearanceSection, null), /* @__PURE__ */_react.default.createElement(MicWebcamSection, null), /* @__PURE__ */_react.default.createElement(HideMyCountrySection, null), /* @__PURE__ */_react.default.createElement(_NotificationSettings.default, null), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
  style: styles.appVersion
}, "v", __VERSION__));
exports.AppSettings = AppSettings;
const ProfileHeader = () => {
  const currentUser = (0, _helpers.currentUserHook)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.profileHeader
  }, /* @__PURE__ */_react.default.createElement(_uikit.UserProfile, {
    avatarUrl: currentUser.avatarUrl,
    displayName: currentUser.displayName,
    username: currentUser.username
  }));
};
var _default = exports.default = (0, _react.memo)(() => {
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.you
  }, /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: "Settings"
  }, /* @__PURE__ */_react.default.createElement(ProfileHeader, null), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "Account",
    icon: "user"
  }, /* @__PURE__ */_react.default.createElement(AccountSection, null)), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: "App",
    icon: "cog"
  }, /* @__PURE__ */_react.default.createElement(AppSettings, null)), /* @__PURE__ */_react.default.createElement(ContactSupportSection, null)));
});