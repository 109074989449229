"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icon = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _Html = _interopRequireDefault(require("./Html"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const bouncing = "xfk0eb1-B";
const iconLoading = "x1wc8ddo-B";
const styles = {
  icon: {
    "Icon__styles.icon": "Icon__styles.icon",
    display: "x3nfvp2",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    opacity: "x1hc1fzr",
    width: "x15vhz18",
    height: "xlzyvqe",
    fontFamily: "xyw5xrh",
    fontStyle: "x1j61x8r",
    fontWeight: "x1fcty0u",
    textDecoration: "x1r8a4m5",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    backfaceVisibility: "xlp1x4z",
    margin: "xrvdr0l",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xrv4cvt",
    "::before_background": "x1y165du",
    "::before_backgroundAttachment": null,
    "::before_backgroundClip": null,
    "::before_backgroundColor": null,
    "::before_backgroundImage": null,
    "::before_backgroundOrigin": null,
    "::before_backgroundPosition": null,
    "::before_backgroundPositionX": null,
    "::before_backgroundPositionY": null,
    "::before_backgroundRepeat": null,
    "::before_backgroundSize": null,
    $$css: true
  },
  loading: {
    "Icon__styles.loading": "Icon__styles.loading",
    height: "xlzyvqe",
    lineHeight: "xo5v014",
    animationName: "x1aerksh",
    animationDuration: "x1c74tu6",
    animationTimingFunction: "x1esw782",
    animationIterationCount: "xa4qsjk",
    $$css: true
  },
  link: {
    "Icon__styles.link": "Icon__styles.link",
    cursor: "x1ypdohk",
    opacity: "x1us6l5c",
    transition: "x19n8f2o",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  circular: {
    "Icon__styles.circular": "Icon__styles.circular",
    borderRadius: "xy3cocn",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    lineHeight: "xo5v014",
    padding: "x1ghdu0b",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    boxShadow: "x12x4fey",
    width: "xk2f0lv",
    height: "x19hywkt",
    $$css: true
  },
  red: {
    "Icon__styles.red": "Icon__styles.red",
    color: "xdi3vc4",
    $$css: true
  },
  yellow: {
    "Icon__styles.yellow": "Icon__styles.yellow",
    color: "x16cd2qt",
    $$css: true
  },
  white: {
    "Icon__styles.white": "Icon__styles.white",
    color: "x11jfisy",
    $$css: true
  },
  green: {
    "Icon__styles.green": "Icon__styles.green",
    color: "xdf6pim",
    $$css: true
  },
  blue: {
    "Icon__styles.blue": "Icon__styles.blue",
    color: "xzwifym",
    $$css: true
  },
  small: {
    "Icon__styles.small": "Icon__styles.small",
    lineHeight: "xo5v014",
    fontSize: "x3u6hjl",
    $$css: true
  },
  large: {
    "Icon__styles.large": "Icon__styles.large",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "xkzkwiv",
    $$css: true
  },
  big: {
    "Icon__styles.big": "Icon__styles.big",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "xodt8ld",
    $$css: true
  },
  huge: {
    "Icon__styles.huge": "Icon__styles.huge",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "x7ckqch",
    $$css: true
  },
  massive: {
    "Icon__styles.massive": "Icon__styles.massive",
    lineHeight: "xo5v014",
    verticalAlign: "xxymvpz",
    fontSize: "x1ce735w",
    $$css: true
  },
  dot: {
    "Icon__styles.dot": "Icon__styles.dot",
    fontWeight: "x1uu0p8",
    margin: "x1qyo9ns",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    fontSize: "xrv4cvt",
    width: "x6ddo64",
    height: "x1jtizjd",
    display: "x1rg5ohu",
    backgroundColor: "x14xb6xe",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    animationName: "x2va7gc",
    animationDuration: "x7tkt5m",
    animationIterationCount: "xa4qsjk",
    animationTimingFunction: "xa3vuyk",
    ":nth-child(2)_animationDelay": "x13cvu4a",
    ":nth-child(3)_animationDelay": "x2bb5ep",
    $$css: true
  },
  animatedEllipsis: {
    "Icon__styles.animatedEllipsis": "Icon__styles.animatedEllipsis",
    width: "xh8yej3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  }
};
const AnimatedEllipsis = t0 => {
  const $ = (0, _compilerRuntime.c)(8);
  const {
    onClick,
    ref,
    style
  } = t0;
  let t1;
  if ($[0] !== style) {
    t1 = [styles.icon, style, styles.animatedEllipsis];
    $[0] = style;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.dot]
    });
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.dot]
    });
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.dot]
    });
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  let t5;
  if ($[5] !== onClick || $[6] !== t1) {
    t5 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t1,
      ref,
      onClick
    }, t2, t3, t4);
    $[5] = onClick;
    $[6] = t1;
    $[7] = t5;
  } else {
    t5 = $[7];
  }
  return t5;
};
const Icon = t0 => {
  const $ = (0, _compilerRuntime.c)(18);
  const {
    ref,
    id,
    name,
    className,
    color,
    size,
    link,
    circular,
    onClick
  } = t0;
  const t1 = size && styles[size];
  const t2 = color && styles[color];
  const t3 = circular && styles.circular;
  const t4 = link && styles.link;
  const t5 = className instanceof Object && className;
  let combinedStyles;
  let t6;
  if ($[0] !== t1 || $[1] !== t2 || $[2] !== t3 || $[3] !== t4 || $[4] !== t5) {
    combinedStyles = [styles.icon, t1, t2, t3, t4, t5];
    t6 = stylex.props(combinedStyles);
    $[0] = t1;
    $[1] = t2;
    $[2] = t3;
    $[3] = t4;
    $[4] = t5;
    $[5] = combinedStyles;
    $[6] = t6;
  } else {
    combinedStyles = $[5];
    t6 = $[6];
  }
  const {
    className: stylexClassName
  } = t6;
  let classNames;
  if ($[7] !== className || $[8] !== name || $[9] !== stylexClassName) {
    classNames = ["icon", stylexClassName];
    if (name) {
      classNames.push(name);
    }
    if (typeof className === "string") {
      classNames.push(className);
    }
    $[7] = className;
    $[8] = name;
    $[9] = stylexClassName;
    $[10] = classNames;
  } else {
    classNames = $[10];
  }
  if (name == "animated ellipsis") {
    let t72;
    if ($[11] !== combinedStyles || $[12] !== onClick) {
      t72 = /* @__PURE__ */_react.default.createElement(AnimatedEllipsis, {
        onClick,
        ref,
        style: combinedStyles
      });
      $[11] = combinedStyles;
      $[12] = onClick;
      $[13] = t72;
    } else {
      t72 = $[13];
    }
    return t72;
  }
  const t7 = classNames.join(" ");
  let t8;
  if ($[14] !== id || $[15] !== onClick || $[16] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement("i", {
      id,
      className: t7,
      onClick,
      ref
    });
    $[14] = id;
    $[15] = onClick;
    $[16] = t7;
    $[17] = t8;
  } else {
    t8 = $[17];
  }
  return t8;
};
exports.Icon = Icon;