"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _gql = require("@/shared/hooks/gql");
var _OnboardingTooltip = require("@/shared/components/OnboardingTooltip");
var _helpers = require("kosmi-sdk/helpers");
var _react = _interopRequireWildcard(require("react"));
var _RoomContext = _interopRequireDefault(require("../contexts/RoomContext"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const STOP_APP = (0, _gql.gql)(`
  mutation StopApp($roomId: String!) {
    stopApp(roomId: $roomId) {
      ok
    }
  }
`);
const SettingsModal = ({
  trigger,
  children
}) => {
  const track = (0, _helpers.useTracking)();
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    trigger,
    onOpen: () => {
      track("UI", "OpenAppSettingsModal", "Open app settings modal");
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "App Settings"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.settings
  }, children)));
};
const InstructionsModal = _a => {
  var _b = _a,
    {
      children
    } = _b,
    props = __objRest(_b, ["children"]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, __spreadValues({}, props), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Header, null, "Usage instructions"), /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, children));
};
const styles = {
  settings: {
    "AppMenuDropdown__styles.settings": "AppMenuDropdown__styles.settings",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    $$css: true
  },
  appMenuImg: {
    "AppMenuDropdown__styles.appMenuImg": "AppMenuDropdown__styles.appMenuImg",
    width: "xw4jnvo",
    height: "x1qx5ct2",
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  appmenuDropdown: {
    "AppMenuDropdown__styles.appmenuDropdown": "AppMenuDropdown__styles.appmenuDropdown",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    zIndex: "x1rozsjd",
    width: "xrostsh",
    maxWidth: "x16q51m2",
    transition: "x1n0frbq",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    "@media(max-height: 625px)_display": "xy0mdsg",
    $$css: true
  },
  appTitle: {
    "AppMenuDropdown__styles.appTitle": "AppMenuDropdown__styles.appTitle",
    textOverflow: "xlyipyv",
    display: "x78zum5",
    width: "xo5x3gg",
    minWidth: "xxsxjwm",
    maxWidth: "x146dmfp",
    height: "x1gnnpzl",
    minHeight: "x1rz828m",
    maxHeight: "x990d50",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    whiteSpace: "xuxw1ft",
    fontSize: "x4z9k3i",
    textAlign: "xdpxx8g",
    alignItems: "x6s0dn4",
    $$css: true
  }
};
const AppMenuDropdown = (0, _helpers.withDialogs)(({
  ref,
  onClose,
  confirm,
  appSettings,
  instructions,
  trigger,
  visible,
  children
}) => {
  const roomId = (0, _helpers.useRoomId)();
  const [stopApp] = (0, _gql.useMutation)(STOP_APP, {
    variables: {
      roomId
    }
  });
  if (!trigger) return null;
  return /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    position: "center",
    open: visible,
    trigger,
    onClose,
    ref
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, appSettings && /* @__PURE__ */_react.default.createElement(SettingsModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      text: "App Settings",
      icon: "setting"
    })
  }, appSettings), instructions && /* @__PURE__ */_react.default.createElement(InstructionsModal, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
      text: "Instructions",
      icon: "help circle"
    })
  }, instructions), children, /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu.Item, {
    text: "Stop App",
    icon: "remove",
    onClick: () => __async(void 0, null, function* () {
      if (!confirm) return;
      if (yield confirm("This will stop the app currently running. Do you want to continue?")) {
        yield stopApp();
        onClose();
      }
    })
  })));
});
var _default = exports.default = (0, _react.memo)(() => {
  const [showDropdown, setShowDropdown] = _react.default.useState(false);
  const [showTooltip, setShowTooltip] = _react.default.useState(!window.localStorage.getItem("AppMenuTooltipSeen"));
  const roomId = (0, _helpers.useRoomId)();
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const {
    appModule,
    runningApp,
    Settings
  } = _react.default.useContext(_RoomContext.default);
  return ["WELCOME", "LOBBY", "SPACES"].indexOf(runningApp || "") === -1 ? /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.appmenuDropdown
  }, /* @__PURE__ */_react.default.createElement(_OnboardingTooltip.OnboardingTooltip, {
    open: !isMobile && showTooltip,
    content: "Here you can access App settings and close the App",
    onClick: () => {
      window.localStorage.setItem("AppMenuTooltipSeen", "true");
      setShowTooltip(false);
    },
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(AppMenuDropdown, {
      appSettings: Settings && /* @__PURE__ */_react.default.createElement(Settings, null),
      instructions: appModule && appModule.instructions,
      visible: showDropdown,
      onClose: () => {
        setShowDropdown(false);
      },
      trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
        style: __spreadValues({
          height: 45,
          padding: 0,
          width: "100%",
          margin: 0,
          alignItems: "center",
          borderRadius: 0,
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 0,
          backgroundColor: "var(--primary-dark-5)"
        }, showDropdown && {
          backgroundColor: "var(--primary-dark-5)"
        }),
        color: "blue",
        onClick: () => {
          setShowDropdown(!showDropdown);
        }
      }, appModule && appModule.icon && /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.appMenuImg,
        src: appModule.icon
      }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.appTitle
      }, appModule && appModule.name || runningApp), /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "caret down"
      }))
    }, appModule && appModule.AppMenu && /* @__PURE__ */_react.default.createElement(appModule.AppMenu, {
      roomId
    })))
  })) : null;
});