"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Capacitor } from "@capacitor/core";
import useRegisterWebPushNotifications from "@/shared/hooks/useRegisterWebPushNotifications";
import { useCallback } from "react";
import { useEnablePushNotifications } from "@/app/hooks/useCapacitor";
import { PushNotifications } from "@capacitor/push-notifications";
export const useCheckPermissions = () => {
  return useCallback(() => __async(void 0, null, function* () {
    if (Capacitor.isNativePlatform()) {
      return (yield PushNotifications.checkPermissions()).receive;
    }
    return Notification.permission;
  }), []);
};
export default () => {
  const registerWebPushNotifications = useRegisterWebPushNotifications();
  const registerPushNotifications = useEnablePushNotifications();
  return useCallback(() => __async(void 0, null, function* () {
    if (Capacitor.isNativePlatform()) {
      return yield registerPushNotifications();
    } else {
      return yield registerWebPushNotifications();
    }
  }), [registerPushNotifications, registerWebPushNotifications]);
};
