"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _gql = require("@/shared/hooks/gql");
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _linkifyReact = _interopRequireDefault(require("linkify-react"));
var _reactRouterDom = require("react-router-dom");
var _reactStringReplace = _interopRequireDefault(require("react-string-replace"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function isNotNull(value) {
  return value !== null;
}
const styles = {
  image: {
    "Notifications__styles.image": "Notifications__styles.image",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    objectFit: "x19kjcj4",
    $$css: true
  },
  title: {
    "Notifications__styles.title": "Notifications__styles.title",
    fontSize: "x1pvqxga",
    fontWeight: "x1s688f",
    marginBottom: "xieb3on",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    $$css: true
  },
  joinRoomAction: {
    "Notifications__styles.joinRoomAction": "Notifications__styles.joinRoomAction",
    alignItems: "x6s0dn4",
    backgroundColor: "x6kzxpx",
    borderRadius: "x1sxf85j",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    boxShadow: "x3e6q68",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    marginTop: "x1sy10c2",
    minHeight: "x2lklch",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    width: "xh8yej3",
    $$css: true
  },
  joinRoomActionAvatar: {
    "Notifications__styles.joinRoomActionAvatar": "Notifications__styles.joinRoomActionAvatar",
    width: "x1pju0fl",
    height: "x10wjd1d",
    margin: "x1v36x0e",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  invitingUser: {
    "Notifications__styles.invitingUser": "Notifications__styles.invitingUser",
    display: "x78zum5",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    justifyContent: "x1nhvcw1",
    width: "xh8yej3",
    $$css: true
  },
  invitingUserAvatar: {
    "Notifications__styles.invitingUserAvatar": "Notifications__styles.invitingUserAvatar",
    height: "x1qx5ct2",
    width: "xw4jnvo",
    marginRight: "x17ituo4",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  inviteText: {
    "Notifications__styles.inviteText": "Notifications__styles.inviteText",
    width: "xh8yej3",
    padding: "x7z7khe",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  notificationModalContent: {
    "Notifications__styles.notificationModalContent": "Notifications__styles.notificationModalContent",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    width: "xh8yej3",
    height: "xg7h5cd",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  notificationsWrapper: {
    "Notifications__styles.notificationsWrapper": "Notifications__styles.notificationsWrapper",
    width: "xh8yej3",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    overflowY: "x1odjw0f",
    $$css: true
  },
  empty: {
    "Notifications__styles.empty": "Notifications__styles.empty",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  notificationDropdown: {
    "Notifications__styles.notificationDropdown": "Notifications__styles.notificationDropdown",
    position: "x1n2onr6",
    height: "x16nrsnc",
    width: "xdzyupr",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    "@media (max-width: 1000px)_height": "x1ltrzbh",
    "@media (max-width: 1000px)_width": "x1nkzv30",
    $$css: true
  },
  dropdownHeader: {
    "Notifications__styles.dropdownHeader": "Notifications__styles.dropdownHeader",
    display: "x78zum5",
    width: "xh8yej3",
    justifyContent: "x1qughib",
    padding: "xetlade",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    alignItems: "x6s0dn4",
    maxHeight: "x127lh2o",
    "@media (max-width: 1000px)_fontSize": "x1b9ffsh",
    "@media (max-width: 1000px)_padding": "x1bstub3",
    "@media (max-width: 1000px)_paddingInline": null,
    "@media (max-width: 1000px)_paddingStart": null,
    "@media (max-width: 1000px)_paddingLeft": null,
    "@media (max-width: 1000px)_paddingEnd": null,
    "@media (max-width: 1000px)_paddingRight": null,
    "@media (max-width: 1000px)_paddingBlock": null,
    "@media (max-width: 1000px)_paddingTop": null,
    "@media (max-width: 1000px)_paddingBottom": null,
    $$css: true
  },
  emptyNotifications: {
    "Notifications__styles.emptyNotifications": "Notifications__styles.emptyNotifications",
    display: "x78zum5",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    height: "x5yr21d",
    width: "xh8yej3",
    fontSize: "xosj86m",
    $$css: true
  }
};
const NOTIFICATION_QUERY = (0, _gql.gql)(`
  query NotificationQuery2 {
    currentUser {
      id
      connectionId
      user {
        id
        username
        isAnonymous
        notifications {
          id
          title
          text
          type
          timestamp
          seen
          autoOpen
          sender {
            id
            username
            displayName
            avatarUrl
          }
        }
      }
    }
  }
`);
const ROOM_QUERY = (0, _gql.gql)(`
  query RoomActionQuery($roomId: String!) {
    room(id: $roomId) {
      id
      state {
        metadata {
          roomName
          avatarUrl
        }
      }
    }
  }
`);
const JoinRoomAction = (0, _react.memo)(({
  roomId,
  joinRoomCallback
}) => {
  var _a, _b, _c, _d, _e, _f;
  const history = (0, _reactRouterDom.useHistory)();
  const {
    data
  } = (0, _gql.useQuery)(ROOM_QUERY, {
    variables: {
      roomId
    }
  });
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.joinRoomAction
  }, data ? /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, (_c = (_b = (_a = data.room) == null ? void 0 : _a.state) == null ? void 0 : _b.metadata) == null ? void 0 : _c.roomName, " ", /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    circular: true,
    src: ((_f = (_e = (_d = data.room) == null ? void 0 : _d.state) == null ? void 0 : _e.metadata) == null ? void 0 : _f.avatarUrl) || "",
    loading: "lazy",
    className: styles.joinRoomActionAvatar
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "yellow",
    inverted: true,
    onClick: () => {
      history.push("/room/" + roomId);
      if (joinRoomCallback) {
        joinRoomCallback(true);
      }
    }
  }, "Join Room"), " ") : /* @__PURE__ */_react.default.createElement(_uikit.Loader, null));
});
const addKosmiEmbeds = body => (0, _reactStringReplace.default)(body, /(https:\/\/img\.kosmi\.io\/\w+[.]\S+)/g, match => /* @__PURE__ */_react.default.createElement(_uikit.Image, {
  className: styles.image,
  src: match,
  loading: "lazy"
}));
const ViewNotificationModal = ({
  notification,
  onClose,
  currentUser
}) => {
  var _a, _b, _c, _d, _e;
  let content = null;
  const username = (currentUser == null ? void 0 : currentUser.username) || "";
  const handleClose = (0, _react.useCallback)(() => {
    onClose(false);
  }, [onClose]);
  if (notification) {
    if (notification.type === "INVITE") {
      content = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.notificationModalContent
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.invitingUser
      }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.invitingUserAvatar,
        circular: true,
        src: ((_a = notification == null ? void 0 : notification.sender) == null ? void 0 : _a.avatarUrl) || ""
      }), (_b = notification == null ? void 0 : notification.sender) == null ? void 0 : _b.displayName), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.inviteText
      }, "has invited you to join a room"), /* @__PURE__ */_react.default.createElement(JoinRoomAction, {
        roomId: (notification == null ? void 0 : notification.text) || "",
        joinRoomCallback: onClose
      }));
    } else {
      content = /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.notificationModalContent
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.invitingUser
      }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
        className: styles.invitingUserAvatar,
        circular: true,
        src: ((_c = notification == null ? void 0 : notification.sender) == null ? void 0 : _c.avatarUrl) || ""
      }), (_d = notification == null ? void 0 : notification.sender) == null ? void 0 : _d.displayName), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.title
      }, notification.title), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, /* @__PURE__ */_react.default.createElement(_linkifyReact.default, null, (_e = notification == null ? void 0 : notification.text) == null ? void 0 : _e.replace("[username]", username !== "anonymous" ? username : "yourusername").split("\n").map((line, i) => /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        key: i
      }, addKosmiEmbeds(line))))));
    }
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    open: !!notification,
    onClose: handleClose
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, content));
};
const Notifications = ({
  ref,
  onClose
}) => {
  var _a, _b;
  const {
    data,
    refetch
  } = (0, _gql.useQuery)(NOTIFICATION_QUERY);
  const notifications = ((_b = (_a = data == null ? void 0 : data.currentUser) == null ? void 0 : _a.user) == null ? void 0 : _b.notifications) || [];
  const prevNotificationsRef = (0, _react.useRef)(notifications);
  const [viewNotification, setViewNotification] = (0, _react.useState)(null);
  const notificationsString = JSON.stringify(notifications);
  (0, _react.useEffect)(() => {
    const notifications2 = JSON.parse(notificationsString);
    const prevNotifications = prevNotificationsRef.current;
    if (notifications2.length >= prevNotifications.length) {
      const notification = notifications2[0];
      if (notification == null ? void 0 : notification.autoOpen) {
        setViewNotification(notification);
      }
      prevNotificationsRef.current = notifications2;
    }
  }, [notificationsString]);
  const items = (0, _react.useMemo)(() => {
    const notifications2 = JSON.parse(notificationsString);
    return notifications2.filter(isNotNull).map(n => {
      var _a2, _b2;
      return {
        onClick: () => setViewNotification(n),
        title: n.title || "",
        content: ((_a2 = n == null ? void 0 : n.sender) == null ? void 0 : _a2.displayName) || "",
        unread: !n.seen,
        avatarUrl: ((_b2 = n == null ? void 0 : n.sender) == null ? void 0 : _b2.avatarUrl) || "",
        timestamp: n.timestamp || 0,
        key: n.timestamp
      };
    });
  }, [notificationsString]);
  const handleClose = (0, _react.useCallback)(closeDropdown => {
    setViewNotification(null);
    if (closeDropdown) {
      if (onClose) {
        onClose();
      }
    }
    if (viewNotification == null ? void 0 : viewNotification.autoOpen) {
      refetch();
    }
  }, [onClose, refetch, viewNotification]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.notificationDropdown, notifications.length === 0 && styles.empty],
    ref
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.dropdownHeader
  }, "Notifications", /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    color: "white",
    inverted: true,
    style: {
      borderWidth: 0,
      fontSize: 18,
      visibility: "hidden"
    }
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "cog"
  }))), /* @__PURE__ */_react.default.createElement(ViewNotificationModal, {
    notification: viewNotification,
    currentUser: data == null ? void 0 : data.currentUser,
    onClose: handleClose
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.notificationsWrapper
  }, items.length ? /* @__PURE__ */_react.default.createElement(_uikit.MessageList, {
    items
  }) : /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.emptyNotifications
  }, "You have no notifications!")));
};
var _default = exports.default = Notifications;