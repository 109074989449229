"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabView = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _Menu = require("./Menu");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  tabView: {
    "TabView__styles.tabView": "TabView__styles.tabView",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    minHeight: "x2lwn1j",
    maxWidth: "x193iq5w",
    $$css: true
  },
  menu: {
    "TabView__styles.menu": "TabView__styles.menu",
    padding: "xfawy5m",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    $$css: true
  },
  menuItem: {
    "TabView__styles.menuItem": "TabView__styles.menuItem",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: "x2lah0s",
    flexBasis: null,
    fontWeight: "x1xlr1w8",
    alignItems: "x6s0dn4",
    width: "x3hqpx7",
    position: "x1n2onr6",
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    justifyContent: "xl56j7k",
    transition: "xxksqoq",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    $$css: true
  },
  swipableWrapper: {
    "TabView__styles.swipableWrapper": "TabView__styles.swipableWrapper",
    display: "x78zum5",
    minHeight: "x2lwn1j",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    position: "x1n2onr6",
    alignItems: "x6s0dn4",
    width: "xh8yej3",
    $$css: true
  },
  tabviewItem: {
    "TabView__styles.tabviewItem": "TabView__styles.tabviewItem",
    maxWidth: "x193iq5w",
    minHeight: "x2lwn1j",
    display: "x78zum5",
    height: "x5yr21d",
    flexDirection: "xdt5ytf",
    transform: "xmyttsv",
    transition: "x1alzeyj",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flexShrink: null,
    flex: "x1b0bnr5",
    flexGrow: null,
    flexBasis: "x1l7klhg",
    minWidth: "xgqtt45",
    justifyContent: "x1nhvcw1",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  underline: {
    "TabView__styles.underline": "TabView__styles.underline",
    position: "x10l6tqk",
    bottom: "xbfrwjf",
    backgroundColor: "x14xb6xe",
    opacity: "x1iy03kw",
    transition: "xxdbxwx",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    borderRadius: "x12oqio5",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    height: "x36qwtl",
    zIndex: "xhtitgo",
    $$css: true
  },
  setWidth: (percentage, width) => [{
    "TabView__styles.setWidth": "TabView__styles.setWidth",
    width: "x17fnjtu",
    left: "x101gtxs",
    insetInlineStart: null,
    insetInlineEnd: null,
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${width}%`),
    "--left": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(`${percentage - width / 2}%`)
  }]
};
const TabViewItem = t0 => {
  const $ = (0, _compilerRuntime.c)(6);
  const {
    children,
    className: classNameProp,
    styleProps
  } = t0;
  let t1;
  if ($[0] !== classNameProp) {
    t1 = [styles.tabviewItem, classNameProp];
    $[0] = classNameProp;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== children || $[3] !== styleProps || $[4] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      styleProps,
      style: t1
    }, children);
    $[2] = children;
    $[3] = styleProps;
    $[4] = t1;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  return t2;
};
const Underline = t0 => {
  const $ = (0, _compilerRuntime.c)(5);
  const {
    percentage,
    width
  } = t0;
  let t1;
  if ($[0] !== percentage || $[1] !== width) {
    t1 = styles.setWidth(percentage, width);
    $[0] = percentage;
    $[1] = width;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.underline, t1]
    });
    $[3] = t1;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
const SwipableWrapper = t0 => {
  const $ = (0, _compilerRuntime.c)(7);
  const {
    children,
    index
  } = t0;
  let t1;
  if ($[0] !== children || $[1] !== index) {
    let t22;
    if ($[3] !== index) {
      t22 = (c, i) => {
        var _a;
        return _react.default.cloneElement(c, {
          key: i,
          styleProps: __spreadProps(__spreadValues({}, (_a = c == null ? void 0 : c.props) == null ? void 0 : _a.styleProps), {
            transform: `translate3d(-${index * 100}%,0,0)`
          })
        });
      };
      $[3] = index;
      $[4] = t22;
    } else {
      t22 = $[4];
    }
    t1 = _react.default.Children.map(children, t22);
    $[0] = children;
    $[1] = index;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[5] !== t1) {
    t2 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.swipableWrapper
    }, t1);
    $[5] = t1;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  return t2;
};
const TabViewMenu = t0 => {
  const $ = (0, _compilerRuntime.c)(15);
  const {
    index,
    onTabChange,
    onTabClick,
    items
  } = t0;
  const t1 = 100 / items.length - 8;
  const t2 = (index + 1) / items.length * 100 - 100 / items.length / 2;
  let t3;
  if ($[0] !== t1 || $[1] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(Underline, {
      width: t1,
      percentage: t2
    });
    $[0] = t1;
    $[1] = t2;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] !== index || $[4] !== items || $[5] !== onTabChange || $[6] !== onTabClick) {
    let t52;
    if ($[8] !== index || $[9] !== onTabChange || $[10] !== onTabClick) {
      t52 = (item, i) => /* @__PURE__ */_react.default.createElement(_Menu.Menu.Item, {
        key: i,
        active: i === index,
        onClick: () => {
          onTabChange(i);
          onTabClick && onTabClick(i);
        },
        className: styles.menuItem
      }, item);
      $[8] = index;
      $[9] = onTabChange;
      $[10] = onTabClick;
      $[11] = t52;
    } else {
      t52 = $[11];
    }
    t4 = items.map(t52);
    $[3] = index;
    $[4] = items;
    $[5] = onTabChange;
    $[6] = onTabClick;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  let t5;
  if ($[12] !== t3 || $[13] !== t4) {
    t5 = /* @__PURE__ */_react.default.createElement(_Menu.Menu, {
      tabular: true,
      className: styles.menu
    }, t3, t4);
    $[12] = t3;
    $[13] = t4;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  return t5;
};
const TabView = exports.TabView = Object.assign((0, _react.memo)(t0 => {
  var _a, _b;
  const $ = (0, _compilerRuntime.c)(17);
  let {
    ref,
    children,
    onTabChange,
    onTabClick,
    forceIndex,
    menu,
    className: classNameProp
  } = t0;
  menu = menu === void 0 ? true : menu;
  const childCompnents = _react.default.Children.toArray(children).filter(_temp);
  const [_index, setIndex] = (0, _react.useState)(0);
  const index = forceIndex !== void 0 ? forceIndex : _index;
  let t1;
  if ($[0] !== index || $[1] !== onTabChange) {
    t1 = () => {
      onTabChange && onTabChange(index);
    };
    $[0] = index;
    $[1] = onTabChange;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] !== forceIndex || $[4] !== index || $[5] !== onTabChange) {
    t2 = [index, onTabChange, forceIndex];
    $[3] = forceIndex;
    $[4] = index;
    $[5] = onTabChange;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  (0, _react.useEffect)(t1, t2);
  const t3 = _Html.default;
  const t4 = ref;
  let t5;
  if ($[7] !== classNameProp) {
    t5 = [styles.tabView, classNameProp];
    $[7] = classNameProp;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const t6 = childCompnents.length > 1 && menu && /* @__PURE__ */_react.default.createElement(TabViewMenu, {
    index,
    items: childCompnents.map(_temp2),
    onTabChange: i => setIndex(i),
    onTabClick
  });
  let t7;
  if ($[9] !== childCompnents || $[10] !== index) {
    t7 = !((_b = (_a = childCompnents[index]) == null ? void 0 : _a.props) == null ? void 0 : _b.empty) && /* @__PURE__ */_react.default.createElement(SwipableWrapper, {
      index
    }, childCompnents);
    $[9] = childCompnents;
    $[10] = index;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  let t8;
  if ($[12] !== t3.div || $[13] !== t5 || $[14] !== t6 || $[15] !== t7) {
    t8 = /* @__PURE__ */_react.default.createElement(t3.div, {
      ref: t4,
      style: t5
    }, t6, t7);
    $[12] = t3.div;
    $[13] = t5;
    $[14] = t6;
    $[15] = t7;
    $[16] = t8;
  } else {
    t8 = $[16];
  }
  return t8;
}), {
  Item: TabViewItem,
  Menu: TabViewMenu
});
function _temp(c) {
  return !!c;
}
function _temp2(item) {
  var _a;
  return (_a = item == null ? void 0 : item.props) == null ? void 0 : _a.name;
}