"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _reactRouterDom = require("react-router-dom");
var _globe = _interopRequireDefault(require("@/shared/components/Roomlist/globe.svg"));
var _Roomlist = _interopRequireWildcard(require("@/shared/components/Roomlist"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _AppContext = _interopRequireDefault(require("@/app/AppContext"));
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
var _useTracking = require("@/shared/hooks/useTracking");
var _components = require("@/shared/components");
var _OnboardingTooltip = require("@/shared/components/OnboardingTooltip");
var _LoginWall = _interopRequireDefault(require("@/shared/components/LoginWall"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const styles = {
  roomsDropdownContext: {
    "index__styles.roomsDropdownContext": "index__styles.roomsDropdownContext",
    marginTop: "xcxhlts",
    $$css: true
  },
  roomsDropdown: {
    "index__styles.roomsDropdown": "index__styles.roomsDropdown",
    maxHeight: "x1rq5m6k",
    width: "xh8yej3",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    flexDirection: "xdt5ytf",
    backgroundColor: "xjbqb8w",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    display: "x78zum5",
    minWidth: "xgqtt45",
    maxWidth: "x65f84u",
    height: "xg7h5cd",
    "@media(max-width: 1000px)_minWidth": "x12e5sv2",
    "@media(max-width: 1000px)_maxWidth": "xaq6xpw",
    "@media(max-width: 1000px)_height": "x1h7qdu3",
    $$css: true
  },
  roomTitle: {
    "index__styles.roomTitle": "index__styles.roomTitle",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    maxHeight: "x19phtnz",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  tall: {
    "index__styles.tall": "index__styles.tall",
    height: "x1egiwwb",
    $$css: true
  },
  roomIcon: {
    "index__styles.roomIcon": "index__styles.roomIcon",
    width: "xw4jnvo",
    height: "x1qx5ct2",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    marginRight: "xyo1k6t",
    marginInlineStart: null,
    marginInlineEnd: null,
    "@media(max-width: 1000px)_width": "x1s1rvhm",
    "@media(max-width: 1000px)_height": "x1gmjqbg",
    $$css: true
  },
  roomOnboardingMessage: {
    "index__styles.roomOnboardingMessage": "index__styles.roomOnboardingMessage",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x1cy8zhl",
    height: "x10wjd1d",
    justifyContent: "x1qughib",
    marginBottom: "x14ler8",
    $$css: true
  },
  roomName: {
    "index__styles.roomName": "index__styles.roomName",
    display: "x78zum5",
    position: "x1n2onr6",
    margin: "x19bbpc0",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    backgroundColor: "x1vaw2wd",
    padding: "xjfvgm7",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    borderTopLeftRadius: "x1hs5g0s",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderTopRightRadius: "xbjeahn",
    width: "x1l2rt3b",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    transition: "x1jte46n",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    textAlign: "xdpxx8g",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    ":hover_cursor": "x1277o0a",
    ":active_transform": "xk4oym4",
    $$css: true
  },
  highlight: {
    "index__styles.highlight": "index__styles.highlight",
    backgroundColor: "x1hhg5nt",
    $$css: true
  },
  unreadMessages: {
    "index__styles.unreadMessages": "index__styles.unreadMessages",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    color: "x16cd2qt",
    fontSize: "x1k6wstc",
    height: "xx3o462",
    "@media(max-width: 1000px)_display": "x4m06ut",
    $$css: true
  },
  roomsDropdownWrapper: {
    "index__styles.roomsDropdownWrapper": "index__styles.roomsDropdownWrapper",
    maxHeight: "x10tl43w",
    width: "xh8yej3",
    display: "x78zum5",
    overflow: "xysyzu8",
    overflowX: null,
    overflowY: null,
    $$css: true
  }
};
const useCurrentRoom = () => {
  var _a, _b;
  const {
    data
  } = (0, _Roomlist.useUserRoomDataCached)();
  const location = (0, _reactRouterDom.useLocation)();
  const anyUnreadMessages = !!((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.find(r => (r == null ? void 0 : r.unreadMessages) && r.unreadMessages > 0));
  let result = null;
  if (location.pathname === "/lobby") {
    result = {
      currentRoom: {
        id: "lobby",
        state: {
          metadata: {
            roomName: "Lobby"
          }
        }
      },
      anyUnreadMessages
    };
  } else if (data == null ? void 0 : data.userRooms) {
    const room = {
      currentRoom: (_b = data == null ? void 0 : data.userRooms) == null ? void 0 : _b.map(r => {
        var _a2;
        return __spreadProps(__spreadValues({}, r), {
          id: (_a2 = r == null ? void 0 : r.id) == null ? void 0 : _a2.replace(/^@\w+\//, "")
        });
      }).find(r => {
        const roomId = r.id;
        const prefixedLink = `/room/${roomId}`;
        const link = `/${roomId}`;
        return link === location.pathname || prefixedLink === location.pathname;
      }),
      anyUnreadMessages
    };
    if (room) result = room;
  }
  return result;
};
const RoomMenuLabel = (0, _react.memo)(() => {
  var _a, _b;
  const {
    currentRoom,
    anyUnreadMessages
  } = useCurrentRoom() || {};
  const metadata = ((_a = currentRoom == null ? void 0 : currentRoom.state) == null ? void 0 : _a.metadata) || {};
  const {
    roomName,
    avatarUrl
  } = metadata;
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, currentRoom && /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    src: ((_b = currentRoom == null ? void 0 : currentRoom.id) == null ? void 0 : _b.endsWith("lobby")) ? _globe.default : avatarUrl,
    className: styles.roomIcon,
    circular: true,
    loading: "lazy"
  }), !currentRoom && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomTitle
  }, "My rooms..."), currentRoom && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomTitle
  }, roomName), anyUnreadMessages && /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "circle",
    className: styles.unreadMessages
  }));
});
var _default = exports.default = (0, _react.memo)(() => {
  var _a;
  const appContext = (0, _react.useContext)(_AppContext.default);
  const {
    roomsDropdownOpen,
    setTutorialStep,
    tutorial,
    toggleRoomsDropdown
  } = appContext;
  const [createRoomModalOpen, setCreateRoomModalOpen] = (0, _react.useState)(false);
  const track = (0, _useTracking.useTracking)();
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const location = (0, _reactRouterDom.useLocation)();
  const ref = (0, _react.useRef)(null);
  const {
    data,
    refetch
  } = (0, _Roomlist.useUserRoomDataCached)();
  const currentRoomId = location.pathname.split("/room/")[1];
  const prevRoomIdRef = (0, _react.useRef)(currentRoomId);
  const roomCount = ((_a = data == null ? void 0 : data.userRooms) == null ? void 0 : _a.length) || 0;
  const onClose = (0, _react.useMemo)(() => () => {
    if (roomsDropdownOpen) {
      toggleRoomsDropdown();
    }
  }, [roomsDropdownOpen, toggleRoomsDropdown]);
  (0, _react.useEffect)(() => {
    if (currentRoomId !== prevRoomIdRef.current) {
      prevRoomIdRef.current = currentRoomId;
      onClose();
    }
  }, [currentRoomId, onClose]);
  const {
    setRoomsDropdownisHovered,
    roomsDropdownisHovered
  } = _react.default.useContext(_AppContext.default);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_components.CreateRoomModal, {
    onClose: () => setCreateRoomModalOpen(false),
    onOpen: () => track("UI", "CreateRoomModalOpenedFromTopBar", "CreateRoom Modal Opened from Top Bar"),
    open: createRoomModalOpen
  }), /* @__PURE__ */_react.default.createElement(_uikit.ContextMenu, {
    onClose: () => onClose(),
    fluid: true,
    position: "center",
    open: roomsDropdownOpen,
    className: styles.roomsDropdownContext,
    trigger: /* @__PURE__ */_react.default.createElement(_OnboardingTooltip.OnboardingTooltip, {
      key: currentRoomId,
      flowing: true,
      content: /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        style: styles.roomOnboardingMessage
      }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "You can customize your room name and icon from the room settings."), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, "The room is permanent and you can come back to it anytime!")),
      step: 1,
      totalSteps: 3,
      onClick: () => setTutorialStep(2),
      onSkip: () => {
        setTutorialStep(4);
        track("UI", "OnboardingSkipped", "Onboarding skipped");
      },
      open: !isMobile && tutorial.roomId === currentRoomId && tutorial.step === 1,
      trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
        ref,
        style: [styles.roomName, roomsDropdownisHovered && styles.highlight],
        onMouseEnter: () => {
          setRoomsDropdownisHovered(true);
        },
        onMouseLeave: () => {
          setRoomsDropdownisHovered(false);
        },
        onMouseDown: () => {
          setRoomsDropdownisHovered(true);
        },
        onClick: () => {
          toggleRoomsDropdown();
          if (!roomsDropdownOpen) {
            refetch();
          }
        }
      }, /* @__PURE__ */_react.default.createElement(RoomMenuLabel, null), /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
        name: "caret down"
      })))
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.roomsDropdownWrapper
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.roomsDropdown, roomCount > 10 && styles.tall]
  }, /* @__PURE__ */_react.default.createElement(_Roomlist.default, {
    visible: roomsDropdownOpen,
    onRoomSelected: () => onClose()
  })))));
});