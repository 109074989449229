"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformText = exports.default = void 0;
var _uikit = require("@/uikit");
var _linkifyReact = _interopRequireDefault(require("linkify-react"));
var _react = _interopRequireWildcard(require("react"));
var _reactStringReplace = _interopRequireDefault(require("react-string-replace"));
var _ImageModal = _interopRequireDefault(require("@/room/components/ImageModal"));
var _LoginWall = _interopRequireDefault(require("@/shared/components/LoginWall"));
var _Premium = require("@/shared/components/Premium");
var _UserProfileModal = _interopRequireDefault(require("../UserProfileModal"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _ImageZoomer = _interopRequireDefault(require("./ImageZoomer"));
var _helpers = require("kosmi-sdk/helpers");
var _useIsAdmin = require("@/room/hooks/useIsAdmin");
var _KosmiDropdown = require("@/layout/Desktop/Header/KosmiDropdown");
var _useMessageData = require("@/room/hooks/useMessageData");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const styles = {
  gif: {
    "index__styles.gif": "index__styles.gif",
    maxWidth: "x193iq5w",
    maxHeight: "xmz0i5r",
    minHeight: "xz65tgg",
    width: "x1oysuqx",
    height: "x1m3v4wt",
    objectFit: "x19kjcj4",
    $$css: true
  },
  link: {
    "index__styles.link": "index__styles.link",
    color: "x16cd2qt",
    WebkitTouchCallout: "xpdipgo",
    ":hover_color": "x1ymi4rj",
    $$css: true
  },
  diamond: {
    "index__styles.diamond": "index__styles.diamond",
    width: "x1kky2od",
    height: "xlup9mm",
    $$css: true
  }
};
const addTenorEmbeds = (body, onClick) => (0, _reactStringReplace.default)(body, /(https:\/\/media.tenor.com\/\S+\/\S+.webp)/g, match => {
  return /* @__PURE__ */_react.default.createElement(_ImageZoomer.default, {
    key: match,
    onClick: onClick && (() => onClick(match))
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.gif,
    src: match,
    loading: "lazy"
  }));
});
const addGiphyEmbeds = (body, onClick) => (0, _reactStringReplace.default)(body, /(https:\/\/\w*\.giphy.com\/media\/\w+\/\S+.gif\S*)/g, match => {
  return /* @__PURE__ */_react.default.createElement(_ImageZoomer.default, {
    key: match,
    onClick: onClick && (() => onClick(match))
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.gif,
    src: match,
    loading: "lazy"
  }));
});
const addKosmiEmbeds = (body, onClick) => (0, _reactStringReplace.default)(body, /(https:\/\/img\.kosmi\.io\/[\w-]+[.]\S+)/g, match => {
  return /* @__PURE__ */_react.default.createElement(_ImageZoomer.default, {
    key: match,
    onClick: onClick && (() => onClick(match))
  }, /* @__PURE__ */_react.default.createElement(_uikit.Image, {
    className: styles.gif,
    src: match,
    loading: "lazy"
  }));
});
const addSubscribeButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":subscribe"]', match => /* @__PURE__ */_react.default.createElement(_Premium.PremiumModal, {
  key: match,
  source: "Chat",
  trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 115,
      marginTop: 10,
      background: "linear-gradient(to top, #b6359c, #ef0a6a)",
      backgroundSize: "115% 115%"
    },
    size: "s",
    color: "red"
  }, /* @__PURE__ */_react.default.createElement(_Premium.Diamond, {
    className: styles.diamond
  }), "Subscribe")
}));
const addContactSupportButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":contactsupport"]', match => /* @__PURE__ */_react.default.createElement(_KosmiDropdown.SupportModal, {
  key: match,
  trigger: /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 100,
      marginTop: 10
    },
    size: "s",
    color: "blue"
  }, "Contact us"))
}));
const addInviteButton = (body, _onClick) => (0, _reactStringReplace.default)(body, '[button=":invite"]', match => /* @__PURE__ */_react.default.createElement(_Premium.InviteModal, {
  key: match,
  trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    style: {
      width: 100,
      marginTop: 10
    },
    size: "s",
    color: "blue"
  }, "Learn More")
}));
const transformText = (text, zoomImage) => [addTenorEmbeds, addGiphyEmbeds, addKosmiEmbeds, addInviteButton, addSubscribeButton, addContactSupportButton].reduce((acc, currentValue) => currentValue(acc, zoomImage), text);
exports.transformText = transformText;
const Link = ({
  ref,
  children
}) => {
  return /* @__PURE__ */_react.default.createElement(_linkifyReact.default, {
    ref,
    options: {
      render: {
        url: ({
          attributes,
          content
        }) => {
          return /* @__PURE__ */_react.default.createElement(_uikit.Html.a, {
            target: "_blank",
            rel: "noopener noreferrer",
            href: attributes.href,
            style: styles.link
          }, content);
        }
      }
    }
  }, children);
};
const TransformedContent = (0, _react.memo)(({
  ref,
  body,
  zoomImage,
  noImages
}) => {
  return /* @__PURE__ */_react.default.createElement(Link, {
    ref
  }, noImages ? body : transformText(body, zoomImage));
});
var _default = exports.default = (0, _helpers.withDialogs)((0, _react.memo)(({
  loading,
  messages,
  nextPage,
  confirm,
  deleteMessage,
  noImages,
  noProfile,
  usersThatHaveRead
}) => {
  const isAdmin = (0, _useIsAdmin.useIsAdmin)();
  const currentUser = (0, _helpers.currentUserHook)();
  const [viewUserProfileId, setViewUserProfileId] = (0, _react.useState)("");
  const [imageZoomed, zoomImage] = (0, _react.useState)(null);
  const currentUserId = currentUser == null ? void 0 : currentUser.id;
  const isLoggedIn = !currentUser.isAnonymous;
  const usersTyping = (0, _useMessageData.useUsersTyping)();
  const removeMessage = (0, _react.useCallback)(id => __async(void 0, null, function* () {
    if (confirm && (yield confirm("Delete this message?"))) {
      deleteMessage(id);
      return true;
    }
    return false;
  }), [deleteMessage, confirm]);
  const viewUserProfileInner = (0, _react.useCallback)(userId => {
    if (!noProfile) {
      setViewUserProfileId(userId);
    }
  }, [setViewUserProfileId, noProfile]);
  const closeViewUserProfile = (0, _react.useCallback)(() => {
    setViewUserProfileId("");
  }, []);
  const handleCloseImage = (0, _react.useCallback)(() => {
    zoomImage(null);
  }, []);
  const typingAvatars = (0, _react.useMemo)(() => {
    return usersTyping.map(user => user.avatarUrl);
  }, [usersTyping]);
  const renderMessages = (0, _react.useCallback)(showLoginModal => /* @__PURE__ */_react.default.createElement(_uikit.ChatMessages, {
    usersThatHaveRead,
    nextPage,
    loading,
    typingAvatars,
    messages: messages == null ? void 0 : messages.map((message, i) => {
      var _a, _b;
      const {
        id,
        user,
        body,
        time
      } = message;
      const prevMessage = messages[i - 1];
      const nextMessage = messages[i + 1];
      const pending = !!(id == null ? void 0 : id.startsWith("pending-"));
      const isOwn = currentUserId === (user == null ? void 0 : user.id);
      const isFirstMessage = !prevMessage || ((_a = prevMessage == null ? void 0 : prevMessage.user) == null ? void 0 : _a.id) !== (user == null ? void 0 : user.id);
      const timeToNextMessage = time && (nextMessage == null ? void 0 : nextMessage.time) && (nextMessage == null ? void 0 : nextMessage.time) - time || 0;
      const timeSinceLastMessage = time && (prevMessage == null ? void 0 : prevMessage.time) && time - (prevMessage == null ? void 0 : prevMessage.time) || 0;
      const isLastMessage = !nextMessage || ((_b = nextMessage == null ? void 0 : nextMessage.user) == null ? void 0 : _b.id) !== (user == null ? void 0 : user.id) || timeToNextMessage > 60;
      const hasPrevMessage = !isFirstMessage && timeSinceLastMessage < 60;
      return {
        id: id || "",
        isOwn,
        time: time || 0,
        pending,
        hasPrevMessage,
        isLastMessage,
        prevTime: (prevMessage == null ? void 0 : prevMessage.time) || 0,
        text: body || "",
        avatarUrl: (user == null ? void 0 : user.avatarUrl) || "",
        authorId: (user == null ? void 0 : user.id) || "",
        isSubscribed: !!(user == null ? void 0 : user.isSubscribed),
        authorName: (user == null ? void 0 : user.displayName) || "",
        content: /* @__PURE__ */_react.default.createElement(TransformedContent, {
          key: id,
          body: body || "",
          zoomImage,
          noImages
        }),
        removeMessage: isOwn || isAdmin ? removeMessage : void 0,
        viewUserProfile: noProfile ? void 0 : isLoggedIn ? viewUserProfileInner : showLoginModal
      };
    })
  }), [currentUserId, messages, loading, removeMessage, viewUserProfileInner, isAdmin, isLoggedIn, nextPage, noImages, noProfile, usersThatHaveRead, typingAvatars]);
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, /* @__PURE__ */_react.default.createElement(_UserProfileModal.default, {
    userId: viewUserProfileId,
    open: !!viewUserProfileId,
    onClose: closeViewUserProfile
  }), /* @__PURE__ */_react.default.createElement(_ImageModal.default, {
    open: imageZoomed ? true : false,
    handleClose: handleCloseImage,
    imageSrc: imageZoomed || ""
  }), /* @__PURE__ */_react.default.createElement(_LoginWall.default, null, renderMessages));
}));