"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _uikit = require("@/uikit");
var _Premium = require("@/shared/components/Premium");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _PremiumModal = _interopRequireDefault(require("./PremiumModal"));
var _Diamond = _interopRequireDefault(require("./Diamond"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const styles = {
  paywall: {
    "PaywallMessage__styles.paywall": "PaywallMessage__styles.paywall",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  paywallMessage: {
    "PaywallMessage__styles.paywallMessage": "PaywallMessage__styles.paywallMessage",
    lineHeight: "x1evy7pa",
    textAlign: "x2b8uid",
    fontSize: "x1j61zf2",
    $$css: true
  },
  diamondPaywall: {
    "PaywallMessage__styles.diamondPaywall": "PaywallMessage__styles.diamondPaywall",
    width: "xvy4d1p",
    height: "xxk0z11",
    $$css: true
  }
};
var _default = exports.default = (0, _react.memo)(({
  message,
  source
}) => {
  if (_Premium.hidePremium) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.paywall
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.paywallMessage
  }, message), /* @__PURE__ */_react.default.createElement(_PremiumModal.default, {
    trigger: /* @__PURE__ */_react.default.createElement(_uikit.Button, {
      size: "xl",
      color: "red",
      style: {
        width: 200,
        fontSize: 24,
        marginTop: 20,
        background: "linear-gradient(to top, #b6359c, #ef0a6a)",
        backgroundSize: "115% 115%"
      }
    }, /* @__PURE__ */_react.default.createElement(_Diamond.default, {
      className: styles.diamondPaywall
    }), "Subscribe"),
    source,
    straightToPayment: true
  }));
});