"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.LoginModalInner = exports.LoginModal = exports.GoogleLoginButton = void 0;
var _uikit = require("@/uikit");
var _platform = _interopRequireDefault(require("platform"));
var _gql = require("@/shared/hooks/gql");
var _core = require("@capacitor/core");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _appleSignIn = require("@capacitor-community/apple-sign-in");
var _capacitorGoogleAuth = require("@codetrix-studio/capacitor-google-auth");
var _react = _interopRequireWildcard(require("react"));
var _ConnectionContext = require("@/api/ConnectionContext");
var _EmailLogin = _interopRequireDefault(require("./EmailLogin"));
var _withDialogs = require("@/shared/hooks/withDialogs");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a, _b;
let googleInitialized = false;
const isAndroid = ((_b = (_a = _platform.default) == null ? void 0 : _a.os) == null ? void 0 : _b.family) === "Android";
const isLocalhost = location.hostname == "localhost";
const withOkta = Component => {
  if (location.host.endsWith(".kosmi.business")) {
    const realm = location.host.split(".kosmi.business")[0];
    if (realm === "reddit") {
      return props => {
        const [oktaAuth, setOktaAuth] = _react.default.useState(null);
        (0, _react.useEffect)(() => {
          import("@okta/okta-auth-js").then(okta => {
            const {
              OktaAuth
            } = okta;
            const oktaAuth2 = new OktaAuth({
              issuer: "https://reddit.okta.com/",
              clientId: "0oakjziut53urD5Jq2p7",
              scopes: ["openid profile email"],
              redirectUri: location.href
            });
            setOktaAuth(oktaAuth2);
          });
        }, []);
        return /* @__PURE__ */_react.default.createElement(Component, __spreadProps(__spreadValues({}, props), {
          oktaAuth
        }));
      };
    }
  }
  return props => /* @__PURE__ */_react.default.createElement(Component, __spreadValues({}, props));
};
const GoogleLoginButton = exports.GoogleLoginButton = (0, _withDialogs.withDialogs)(({
  signup,
  onLogin,
  inviteCode,
  alert
}) => {
  const {
    updateClient
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  const [login, {
    loading
  }] = (0, _gql.useMutation)(GOOGLE_LOGIN);
  (0, _react.useEffect)(() => {
    if (!googleInitialized) {
      googleInitialized = true;
      _capacitorGoogleAuth.GoogleAuth.initialize();
    }
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "l",
    className: styles.loginButton,
    loading,
    color: "google plus",
    onClick: () => __async(void 0, null, function* () {
      var _a2, _b2;
      try {
        const result = yield _core.Plugins.GoogleAuth.signIn();
        const accessToken = (_a2 = result == null ? void 0 : result.authentication) == null ? void 0 : _a2.accessToken;
        const {
          data
        } = yield login({
          variables: {
            token: accessToken,
            inviteCode
          }
        });
        const token = (_b2 = data == null ? void 0 : data.loginWithGoogle) == null ? void 0 : _b2.token;
        if (token) {
          window.localStorage.setItem("token", token);
          updateClient();
          onLogin && onLogin();
        }
      } catch (e) {
        console.error(e);
        if (alert) alert(JSON.stringify(e));
      }
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "google"
  }), signup ? "Signup" : "Login", " with Google");
});
const OktaLoginButton = ({
  oktaAuth,
  signup,
  onLogin
}) => {
  const [oktaLogin, {
    loading: oktaLoading
  }] = (0, _gql.useMutation)(OKTA_LOGIN);
  const {
    updateClient
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "l",
    className: styles.loginButton,
    color: "blue",
    loading: oktaLoading,
    onClick: () => __async(void 0, null, function* () {
      var _a2;
      if (!oktaAuth) return;
      oktaAuth.start();
      const {
        tokens: {
          accessToken: {
            accessToken,
            userinfoUrl
          }
        }
      } = yield oktaAuth.token.getWithPopup({
        prompt: "login"
      });
      const {
        data
      } = yield oktaLogin({
        variables: {
          token: accessToken,
          userInfoUrl: userinfoUrl
        }
      });
      const token = (_a2 = data == null ? void 0 : data.loginWithOkta) == null ? void 0 : _a2.token;
      if (token) {
        window.localStorage.setItem("token", token);
      }
      updateClient();
      onLogin && onLogin();
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "circle notch"
  }), signup ? "Signup" : "Login", " with Okta");
};
const AppleLoginButton = ({
  signup,
  onLogin,
  inviteCode
}) => {
  const {
    updateClient
  } = (0, _react.useContext)(_ConnectionContext.ConnectionContext);
  const [login, {
    loading
  }] = (0, _gql.useMutation)(APPLE_LOGIN);
  if (isAndroid && isLocalhost) {
    return null;
  }
  return /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "l",
    className: styles.loginButton,
    loading,
    color: "black",
    onClick: () => __async(void 0, null, function* () {
      var _a2;
      try {
        const appleOptions = {
          clientId: "com.kosmi.kosmi.web",
          scopes: "email name",
          redirectURI: location.host.indexOf("kosmi.dev") !== -1 ? location.origin : "https://app.kosmi.io/"
        };
        const {
          response: {
            authorizationCode,
            identityToken
          }
        } = yield _appleSignIn.SignInWithApple.authorize(appleOptions);
        const parseJwt = token2 => {
          const base64Url = token2.split(".")[1];
          const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
          const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(""));
          return JSON.parse(jsonPayload);
        };
        const {
          aud
        } = parseJwt(identityToken);
        const {
          data
        } = yield login({
          variables: {
            token: authorizationCode,
            native: appleOptions.clientId !== aud,
            inviteCode
          }
        });
        const token = (_a2 = data == null ? void 0 : data.loginWithApple) == null ? void 0 : _a2.token;
        if (token) {
          window.localStorage.setItem("token", token);
          updateClient();
          onLogin && onLogin();
        }
      } catch (e) {
        console.error(e);
      }
    })
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "apple"
  }), signup ? "Signup" : "Login", " with Apple");
};
const LoginForm = ({
  signup,
  oktaAuth,
  inviteCode,
  onLogin
}) => {
  const emailLoginButtonRef = (0, _react.useRef)(null);
  const handleLogin = (0, _react.useCallback)(() => {
    if (onLogin) {
      onLogin();
    }
  }, [onLogin]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Settings.Container, {
    name: signup ? "Signup" : "Login"
  }, /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.loginContainer
  }, /* @__PURE__ */_react.default.createElement(GoogleLoginButton, {
    inviteCode,
    signup: !!signup,
    onLogin: handleLogin
  }), oktaAuth && /* @__PURE__ */_react.default.createElement(OktaLoginButton, {
    onLogin: handleLogin,
    oktaAuth,
    signup
  }), /* @__PURE__ */_react.default.createElement(AppleLoginButton, {
    inviteCode,
    onLogin: handleLogin,
    signup
  }), /* @__PURE__ */_react.default.createElement(_uikit.Button, {
    size: "l",
    color: "white",
    className: styles.loginButton,
    ref: emailLoginButtonRef,
    inverted: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    name: "mail"
  }), signup ? "Signup" : "Login", " with Email")), /* @__PURE__ */_react.default.createElement(_uikit.Settings.Section, {
    name: signup ? "Email Signup" : "Email Login",
    trigger: emailLoginButtonRef
  }, /* @__PURE__ */_react.default.createElement(_EmailLogin.default, {
    onLogin: handleLogin,
    inviteCode,
    signup: !!signup
  })));
};
const LoginModalInner = exports.LoginModalInner = withOkta(({
  inviteCode,
  signup,
  oktaAuth,
  onLogin
}) => {
  const handleLogin = (0, _react.useCallback)(() => {
    onLogin && onLogin();
  }, [onLogin]);
  return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.loginModalInner
  }, /* @__PURE__ */_react.default.createElement(LoginForm, {
    onLogin: handleLogin,
    oktaAuth,
    signup,
    inviteCode
  }));
});
const LoginModal = ({
  ref,
  trigger: Trigger,
  signup,
  open,
  onClose
}) => {
  const [_modalOpen, setModalOpen] = _react.default.useState(false);
  const modalOpen = open === void 0 ? _modalOpen : open;
  const handleClose = (0, _react.useCallback)(() => {
    if (onClose) {
      onClose();
    } else {
      setModalOpen(false);
    }
  }, [onClose]);
  const openModal = (0, _react.useCallback)(() => {
    setModalOpen(true);
  }, []);
  return /* @__PURE__ */_react.default.createElement(_uikit.Modal, {
    ref,
    trigger: Trigger && /* @__PURE__ */_react.default.createElement(Trigger, {
      onClick: openModal
    }),
    open: modalOpen,
    onClose: handleClose,
    preventClosing: true
  }, /* @__PURE__ */_react.default.createElement(_uikit.Modal.Content, null, /* @__PURE__ */_react.default.createElement(LoginModalInner, {
    signup
  })));
};
exports.LoginModal = LoginModal;
const styles = {
  loginContainer: {
    "index__styles.loginContainer": "index__styles.loginContainer",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    $$css: true
  },
  loginButton: {
    "index__styles.loginButton": "index__styles.loginButton",
    marginTop: "x1gslohp",
    marginBottom: "x12nagc",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    width: "x1l2rt3b",
    maxWidth: "x193iq5w",
    height: "x10wjd1d",
    minHeight: "xu0aao5",
    $$css: true
  },
  loginModalInner: {
    "index__styles.loginModalInner": "index__styles.loginModalInner",
    textAlign: "x2b8uid",
    justifyContent: "xl56j7k",
    display: "x78zum5",
    flexDirection: "x1q0g3np",
    alignItems: "x1qjc9v5",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    maxWidth: "x193iq5w",
    $$css: true
  }
};
const OKTA_LOGIN = (0, _gql.gql)(`
  mutation OktaLogin($token: String!, $userInfoUrl: String!) {
    loginWithOkta(token: $token, userInfoUrl: $userInfoUrl) {
      token
    }
  }
`);
const GOOGLE_LOGIN = (0, _gql.gql)(`
  mutation GoogleLogin($token: String!, $inviteCode: String) {
    loginWithGoogle(token: $token, inviteCode: $inviteCode) {
      token
    }
  }
`);
const APPLE_LOGIN = (0, _gql.gql)(`
  mutation AppleLogin($token: String!, $native: Boolean!, $inviteCode: String) {
    loginWithApple(token: $token, native: $native, inviteCode: $inviteCode) {
      token
    }
  }
`);
var _default = exports.default = LoginModal;