"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Player = void 0;
var _uikit = require("@/uikit");
var _react = _interopRequireWildcard(require("react"));
var _ReactPlayer = _interopRequireDefault(require("./ReactPlayer"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _canAutoplay = _interopRequireDefault(require("can-autoplay"));
var _usePersistedState = _interopRequireDefault(require("use-persisted-state"));
var _AVEngine = _interopRequireDefault(require("@/services/AVEngine"));
var _Cinesend = _interopRequireDefault(require("./Cinesend"));
var _VBrowserPlayer = _interopRequireDefault(require("./VBrowserPlayer"));
var _spinner = _interopRequireDefault(require("./spinner.mp4"));
var _withPlaylistHandling = _interopRequireDefault(require("./withPlaylistHandling"));
var _withProxyHandling = _interopRequireDefault(require("./withProxyHandling"));
var _helpers = require("./helpers");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
const useVideoVolumePersistedState = (0, _usePersistedState.default)("video-volume");
const isLocalhost = location.hostname == "localhost";
const tryParseUrl = url => {
  try {
    return new URL(url);
  } catch (_e) {
    return null;
  }
};
const styles = {
  reactPlayer: {
    "index__styles.reactPlayer": "index__styles.reactPlayer",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    backgroundColor: "x1r1mewj",
    $$css: true
  },
  noMouse: {
    "index__styles.noMouse": "index__styles.noMouse",
    pointerEvents: "x47corl",
    $$css: true
  },
  playOverlay: {
    "index__styles.playOverlay": "index__styles.playOverlay",
    zIndex: "xhtitgo",
    width: "xh8yej3",
    height: "x5yr21d",
    background: "x11skabg",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    backdropFilter: "x1ydx4q1",
    position: "x10l6tqk",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    cursor: "x1ypdohk",
    $$css: true
  },
  playIcon: {
    "index__styles.playIcon": "index__styles.playIcon",
    fontSize: "xyywni2",
    $$css: true
  },
  placeholder: {
    "index__styles.placeholder": "index__styles.placeholder",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    position: "x1n2onr6",
    width: "xh8yej3",
    height: "x5yr21d",
    backgroundColor: "x1r1mewj",
    $$css: true
  },
  icon: {
    "index__styles.icon": "index__styles.icon",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: "xyorhqc",
    $$css: true
  },
  playerWrapper: {
    "index__styles.playerWrapper": "index__styles.playerWrapper",
    height: "x5yr21d",
    width: "xh8yej3",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    $$css: true
  }
};
const getClassName = (...styleObj) => {
  const {
    className
  } = stylex.props(styleObj);
  return className;
};
const Player = exports.Player = (0, _react.memo)(({
  autoPlay,
  onBannedUrl,
  onError,
  onSupportedUrl,
  onUnmount,
  paused,
  stream,
  subtitles,
  url,
  onReady,
  onUpdate,
  controls
}) => {
  var _a;
  const [error, setError] = (0, _react.useState)("");
  const [playerPaused, setPlayerPaused] = (0, _react.useState)(false);
  const [muted, setMuted] = (0, _react.useState)(false);
  const [showPlayButton, setShowPlayButton] = (0, _react.useState)(false);
  const [reactPlayer, setReactPlayer] = (0, _react.useState)(null);
  const [position, setPosition] = (0, _react.useState)(void 0);
  const [duration, setDuration] = (0, _react.useState)(void 0);
  const httpUrl = url && (url.startsWith("blob") || url.startsWith("http") ? url : _spinner.default);
  const initialVolume = _AVEngine.default.getCurrentlySpeaking().size > 0 ? _AVEngine.default.dimLevel : _AVEngine.default.initialEmbedVolume;
  const [currentVolume, setCurrentVolume] = useVideoVolumePersistedState(initialVolume);
  const [preDuckUserVolume, setPreduckUserVolume] = (0, _react.useState)(_AVEngine.default.initialEmbedVolume);
  const applySubtitles = (0, _react.useCallback)((subtitles2, offset = 0) => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (!internalPlayer) return;
    if (!internalPlayer.textTracks) return;
    if (!subtitles2) return;
    if (!(subtitles2 == null ? void 0 : subtitles2.cues)) return;
    (0, _helpers.addSubtitles)(internalPlayer, subtitles2, offset);
  }, [reactPlayer]);
  const offsetSubtitles = (0, _react.useCallback)((subtitles2, offset) => applySubtitles(subtitles2, offset), [applySubtitles]);
  const mute = (0, _react.useCallback)(() => setMuted(true), []);
  const unmute = (0, _react.useCallback)(() => {
    setShowPlayButton(false);
    if (currentVolume == 0) return;
    setMuted(false);
  }, [currentVolume]);
  const setVolume = (0, _react.useCallback)(volume => {
    if (volume === 0) {
      mute();
    } else {
      unmute();
    }
    setCurrentVolume(volume);
    localStorage.setItem("embedVolume", String(volume));
  }, [mute, setCurrentVolume, unmute]);
  const seekTo = (0, _react.useCallback)(position2 => {
    if (Number.isNaN(position2)) return;
    if (reactPlayer) {
      return reactPlayer == null ? void 0 : reactPlayer.seekTo(position2);
    }
  }, [reactPlayer]);
  const isPaused = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    const result = yield (0, _helpers.getIsPaused)(internalPlayer);
    return Boolean(result !== null ? result : playerPaused);
  }), [playerPaused, reactPlayer]);
  const pause = (0, _react.useCallback)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    (0, _helpers.pausePlayer)(internalPlayer);
  }, [reactPlayer]);
  const play = (0, _react.useCallback)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    (0, _helpers.unPausePlayer)(internalPlayer);
  }, [reactPlayer]);
  const syncDuration = (0, _react.useCallback)(() => {
    const playerDuration = reactPlayer == null ? void 0 : reactPlayer.getDuration();
    if (playerDuration) {
      setDuration(playerDuration);
    }
  }, [reactPlayer]);
  const bustAutoPlay = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (!internalPlayer) {
      return;
    }
    const playFallback = () => {
      mute();
      play();
      setShowPlayButton(true);
    };
    const {
      result
    } = yield _canAutoplay.default.audio();
    if (!result) {
      playFallback();
    } else {
      const {
        result: videoResult
      } = yield _canAutoplay.default.video({
        inline: true
      });
      if (!videoResult) {
        setShowPlayButton(true);
      }
      if (yield (0, _helpers.getIsPaused)(internalPlayer)) {
        playFallback();
      }
    }
  }), [mute, play, reactPlayer]);
  const playerKey = (_a = reactPlayer == null ? void 0 : reactPlayer._reactInternals) == null ? void 0 : _a.key;
  const handleVideoLoaded = (0, _react.useCallback)(() => __async(void 0, null, function* () {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    bustAutoPlay();
    onReady({
      id: playerKey || "",
      video: internalPlayer,
      offsetSubtitles,
      setVolume,
      seekTo,
      isPaused,
      pause,
      play,
      mute,
      unmute
    });
    if (subtitles) {
      applySubtitles(subtitles);
    }
    onUpdate({
      volume: currentVolume,
      duration,
      muted,
      position,
      paused: playerPaused
    });
    if (internalPlayer instanceof HTMLVideoElement) {
      internalPlayer.oncanplay = () => {
        if (internalPlayer.videoHeight === 0 && internalPlayer.videoWidth === 0) {
          internalPlayer.poster = "";
        }
      };
    }
  }), [reactPlayer, bustAutoPlay, playerKey, onReady, applySubtitles, onUpdate, currentVolume, duration, isPaused, mute, muted, offsetSubtitles, pause, play, playerPaused, position, seekTo, setVolume, subtitles, unmute]);
  const applyStream = (0, _react.useCallback)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (internalPlayer && stream) {
      internalPlayer.src = "";
      internalPlayer.srcObject = stream;
    }
  }, [reactPlayer, stream]);
  const onPlayerError = (0, _react.useCallback)(e => __async(void 0, null, function* () {
    var _a2, _b;
    const error2 = e;
    console.error({
      e
    });
    const errorName = (error2 == null ? void 0 : error2.message) || ((_a2 = error2 == null ? void 0 : error2.name) == null ? void 0 : _a2.toLowerCase());
    if (_helpers.ignoredErrors.indexOf(errorName) !== -1 || _helpers.ignoredErrors.indexOf((_b = error2 == null ? void 0 : error2.name) == null ? void 0 : _b.toLowerCase()) !== -1) {
      return;
    }
    if ((0, _helpers.isHttp)(url) && onError && onError(errorName)) {
      return;
    }
    if (typeof errorName === "string") {
      setError(errorName || "Unknown Error");
    }
  }), [onError, url]);
  const onPlayerReady = (0, _react.useCallback)(newReactPlayer => __async(void 0, null, function* () {
    setReactPlayer(reactPlayer2 => {
      var _a2, _b;
      if (((_a2 = newReactPlayer == null ? void 0 : newReactPlayer._reactInternals) == null ? void 0 : _a2.key) === ((_b = reactPlayer2 == null ? void 0 : reactPlayer2._reactInternals) == null ? void 0 : _b.key)) {
        return reactPlayer2;
      }
      const hls = newReactPlayer.getInternalPlayer("hls");
      if (hls == null ? void 0 : hls.on) {
        hls.on("hlsError", function (_eventName, data) {
          if (data.fatal) {
            if (data.type === "mediaError") {
              hls.recoverMediaError();
            }
          }
        });
      }
      return newReactPlayer;
    });
  }), []);
  const registerEmbedVolumeCallback = (0, _react.useCallback)(() => _AVEngine.default.registerEmbedVolumeCallback(url, level => __async(void 0, null, function* () {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    const currentPlayerVolume = yield (0, _helpers.getInternalPlayerVolume)(internalPlayer);
    setPreduckUserVolume(currentPlayerVolume);
    if (level !== null) {
      if (currentPlayerVolume > level) {
        setCurrentVolume(level);
      }
    } else {
      setCurrentVolume(preDuckUserVolume);
    }
  })), [preDuckUserVolume, reactPlayer, setCurrentVolume, url]);
  const onProgress = (0, _react.useCallback)(({
    playedSeconds
  }) => {
    setPosition(playedSeconds);
    syncDuration();
  }, [syncDuration]);
  const onPlay = (0, _react.useCallback)(() => {
    setPlayerPaused(false);
    syncDuration();
  }, [syncDuration]);
  const onEnded = (0, _react.useCallback)(() => {
    setPlayerPaused(true);
    syncDuration();
  }, [syncDuration]);
  const onPause = (0, _react.useCallback)(() => {
    setPlayerPaused(true);
    syncDuration();
  }, [syncDuration]);
  const onDuration = (0, _react.useCallback)(duration2 => {
    if (duration2) {
      setDuration(duration2);
    }
  }, []);
  const unPauseAndPlay = (0, _react.useCallback)(() => {
    if (!paused) {
      play();
    }
    unmute();
  }, [paused, play, unmute]);
  (0, _react.useEffect)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (!(subtitles == null ? void 0 : subtitles.cues)) {
      (0, _helpers.clearSubtitleCues)(internalPlayer);
    } else {
      applySubtitles(subtitles);
    }
  }, [subtitles, applySubtitles, reactPlayer]);
  (0, _react.useEffect)(() => {
    onUpdate({
      volume: currentVolume,
      duration,
      muted,
      position,
      paused: playerPaused
    });
  }, [currentVolume, duration, muted, position, playerPaused, onUpdate]);
  (0, _react.useEffect)(() => {
    if (muted && showPlayButton && !paused) {
      play();
    }
  }, [muted, showPlayButton, paused, play]);
  (0, _react.useEffect)(() => {
    ;
    (() => __async(void 0, null, function* () {
      const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
      const playerIsMuted = yield (0, _helpers.getIsMuted)(internalPlayer);
      setMuted(playerIsMuted || false);
    }))();
  }, [controls, reactPlayer]);
  const playerOptions = (0, _react.useMemo)(() => (0, _helpers.getPlayerOptions)(autoPlay), [autoPlay]);
  const initializePlayer = (0, _react.useCallback)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (!internalPlayer) return;
    if (!paused) {
      play();
    }
    (0, _helpers.facebookAndVidyardWorkaround)(internalPlayer, setPlayerPaused);
    handleVideoLoaded();
    if (subtitles) {
      applySubtitles(subtitles);
    }
    if (url) {
      registerEmbedVolumeCallback();
    }
  }, [applySubtitles, handleVideoLoaded, paused, play, reactPlayer, registerEmbedVolumeCallback, subtitles, url]);
  const handleUrls = (0, _react.useCallback)(() => {
    var _a2, _b;
    const parsedUrl = tryParseUrl(url);
    if ((url == null ? void 0 : url.startsWith("https://www.twitch.tv/")) && (location.href.startsWith("capacitor") || isLocalhost)) {
      onPlayerError("twitch won't load on capacitor");
    }
    if ((_a2 = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _a2.includes("pluto.tv")) {
      onPlayerError("Force Pluto to use proxy");
    }
    if ((_b = parsedUrl == null ? void 0 : parsedUrl.hostname) == null ? void 0 : _b.includes(".real-debrid.com")) {
      onPlayerError("Force RD to use proxy");
    }
  }, [onPlayerError, url]);
  const handleUnmount = (0, _react.useCallback)(() => {
    const internalPlayer = reactPlayer == null ? void 0 : reactPlayer.getInternalPlayer();
    if (reactPlayer) {
      const hlsPlayer = reactPlayer.getInternalPlayer("hls");
      const dashPlayer = reactPlayer.getInternalPlayer("dash");
      if (hlsPlayer == null ? void 0 : hlsPlayer.destroy) {
        hlsPlayer.destroy();
      } else if (dashPlayer == null ? void 0 : dashPlayer.reset) {
        dashPlayer.reset();
      }
      if (internalPlayer && stream) {
        internalPlayer.src = "";
        internalPlayer.srcObject = null;
        internalPlayer.pause();
      }
    }
    if (onUnmount) {
      onUnmount();
    }
    if (url) {
      _AVEngine.default.removeEmbedVolumeCallback(url);
    }
  }, [reactPlayer, onUnmount, stream, url]);
  (0, _react.useEffect)(() => {
    applyStream();
  }, [stream, applyStream]);
  const initRefs = (0, _react.useRef)({
    initializePlayer,
    handleUrls,
    handleUnmount,
    handleVideoLoaded
  });
  (0, _react.useEffect)(() => {
    initRefs.current = {
      initializePlayer,
      handleUrls,
      handleUnmount,
      handleVideoLoaded
    };
  }, [initializePlayer, handleUrls, handleUnmount, handleVideoLoaded]);
  (0, _react.useEffect)(() => {
    const {
      initializePlayer: initializePlayer2
    } = initRefs.current;
    initializePlayer2();
  }, [playerKey]);
  (0, _react.useEffect)(() => {
    const {
      handleUrls: handleUrls2
    } = initRefs.current;
    handleUrls2();
    return () => {
      const {
        handleUnmount: handleUnmount2
      } = initRefs.current;
      handleUnmount2();
    };
  }, []);
  if (!url && !stream) {
    return null;
  }
  if (url.startsWith("VBrowser://")) return /* @__PURE__ */_react.default.createElement(_VBrowserPlayer.default, {
    onSupportedUrl,
    onBannedUrl,
    sessionId: url.replace("VBrowser://", "")
  });
  if ((0, _helpers.isCinesendUrl)(url)) return /* @__PURE__ */_react.default.createElement(_Cinesend.default, {
    src: url,
    onReady
  });
  if (error) return /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.placeholder
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.icon,
    name: "frown",
    size: "huge"
  }), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, null, error));
  return /* @__PURE__ */_react.default.createElement(_react.default.Fragment, null, !controls && showPlayButton && /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: styles.playOverlay,
    onClick: unPauseAndPlay
  }, /* @__PURE__ */_react.default.createElement(_uikit.Icon, {
    className: styles.playIcon,
    name: "play",
    size: "huge"
  })), /* @__PURE__ */_react.default.createElement(_uikit.Html.div, {
    style: [styles.playerWrapper, !controls && styles.noMouse]
  }, /* @__PURE__ */_react.default.createElement(_ReactPlayer.default, {
    key: httpUrl,
    className: getClassName(styles.reactPlayer),
    url: httpUrl,
    controls,
    muted,
    volume: currentVolume,
    playing: !paused,
    onError: onPlayerError,
    onReady: onPlayerReady,
    config: playerOptions,
    onDuration,
    onProgress,
    onPlay,
    onEnded,
    onPause,
    width: "100%",
    height: "100%",
    playsinline: true
  })));
});
var _default = exports.default = (0, _withPlaylistHandling.default)((0, _withProxyHandling.default)(Player));