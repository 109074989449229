"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Loader = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _colors = require("../layout/colors");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const spin = "xqng64z-B";
const styles = {
  loader: {
    "Loader__styles.loader": "Loader__styles.loader",
    display: "x78zum5",
    fontSize: "x1pvqxga",
    flexDirection: "xdt5ytf",
    fontFamily: "x1l1c18b",
    justifyContent: "xl56j7k",
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    maxHeight: "xmz0i5r",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    height: "x5yr21d",
    alignItems: "x6s0dn4",
    $$css: true
  },
  text: {
    "Loader__styles.text": "Loader__styles.text",
    marginTop: "x1xmf6yo",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    justifyContent: "xl56j7k",
    alignItems: "x6s0dn4",
    $$css: true
  },
  absolute: {
    "Loader__styles.absolute": "Loader__styles.absolute",
    position: "x10l6tqk",
    left: "xu96u03",
    insetInlineStart: null,
    insetInlineEnd: null,
    right: "x3m8u43",
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    $$css: true
  },
  activityIndicator: {
    "Loader__styles.activityIndicator": "Loader__styles.activityIndicator",
    display: "x78zum5",
    alignItems: "x6s0dn4",
    justifyContent: "xl56j7k",
    $$css: true
  },
  size: size => [{
    "Loader__styles.size": "Loader__styles.size",
    width: "x17fnjtu",
    $$css: true
  }, {
    "--width": (val => typeof val === "number" ? val + "px" : val != null ? val : "initial")(size)
  }]
};
const ActivityIndicator = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(15);
  const {
    size,
    color
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      className: "Loader__styles.rotate x1ka1v4i xa4qsjk x1esw782 xemfg65"
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const svgStyle = t1.className;
  let t2;
  if ($[1] !== size) {
    t2 = styles.size(size);
    $[1] = size;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== t2) {
    t3 = [styles.activityIndicator, t2];
    $[3] = t2;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== color) {
    t4 = /* @__PURE__ */_react.default.createElement("circle", {
      cx: "16",
      cy: "16",
      r: "14",
      fill: "none",
      strokeWidth: "4",
      style: {
        stroke: color,
        opacity: 0.2
      }
    });
    $[5] = color;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  let t5;
  if ($[7] !== color) {
    t5 = /* @__PURE__ */_react.default.createElement("circle", {
      cx: "16",
      cy: "16",
      r: "14",
      fill: "none",
      strokeWidth: "4",
      style: {
        stroke: color,
        strokeDasharray: 80,
        strokeDashoffset: 60
      }
    });
    $[7] = color;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== t4 || $[10] !== t5) {
    t6 = /* @__PURE__ */_react.default.createElement("svg", {
      className: svgStyle,
      height: "100%",
      viewBox: "0 0 32 32",
      width: "100%"
    }, t4, t5);
    $[9] = t4;
    $[10] = t5;
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== t3 || $[13] !== t6) {
    t7 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t3
    }, t6);
    $[12] = t3;
    $[13] = t6;
    $[14] = t7;
  } else {
    t7 = $[14];
  }
  return t7;
});
const Loader = exports.Loader = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(17);
  const {
    size,
    className,
    children,
    content,
    id,
    color,
    absolute
  } = t0;
  let indicatorSize = 20;
  if (size === "massive") {
    indicatorSize = 70;
  }
  const t1 = absolute && styles.absolute;
  let t2;
  if ($[0] !== className || $[1] !== t1) {
    t2 = [styles.loader, t1, className];
    $[0] = className;
    $[1] = t1;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== color || $[4] !== size) {
    t3 = color ? color : size === "massive" ? (0, _colors.getVar)("secondary") : (0, _colors.getVar)("foreground");
    $[3] = color;
    $[4] = size;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  let t4;
  if ($[6] !== indicatorSize || $[7] !== t3) {
    t4 = /* @__PURE__ */_react.default.createElement(ActivityIndicator, {
      size: indicatorSize,
      color: t3
    });
    $[6] = indicatorSize;
    $[7] = t3;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  let t5;
  if ($[9] !== children || $[10] !== content) {
    t5 = (content || children) && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.text
    }, content, children);
    $[9] = children;
    $[10] = content;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  let t6;
  if ($[12] !== id || $[13] !== t2 || $[14] !== t4 || $[15] !== t5) {
    t6 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      id,
      style: t2
    }, t4, t5);
    $[12] = id;
    $[13] = t2;
    $[14] = t4;
    $[15] = t5;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  return t6;
});