"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const baseStyles = {
  base: {
    "Html__baseStyles.base": "Html__baseStyles.base",
    boxSizing: "x9f619",
    textSizeAdjust: "x1javzw2",
    touchAction: "xggy1nq",
    userSelect: "x87ps6o",
    WebKitUserSelect: "x1bib5ie",
    outline: "x1a2a7pz",
    WebKitTapHighlightColor: "x1gp3oje",
    WebKitAppearance: "x67wf27",
    appearance: "xjyslct",
    colorScheme: "xntwwlm",
    fontFamily: "x1o3ll5r",
    fontSize: "x1qlqyl8",
    ":focus_outlineColor": "x1xwnvu1",
    ":focus_outlineStyle": "xhlp2gg",
    "::before_boxSizing": "xtql2tq",
    "::before_textSizeAdjust": "x1wooiz5",
    "::before_touchAction": "xpefml7",
    "::before_userSelect": "x1rbqspl",
    "::before_WebKitUserSelect": "x1hxrwom",
    "::after_boxSizing": "xx38sju",
    "::after_textSizeAdjust": "xxcbro4",
    "::after_touchAction": "x3f4zlw",
    "::after_userSelect": "x1jbr1xh",
    "::after_WebKitUserSelect": "x1gxgjh0",
    "::selection_color": "x1pfyes6",
    "::selection_background": "x86bo8",
    $$css: true
  },
  videoBase: {
    "Html__baseStyles.videoBase": "Html__baseStyles.videoBase",
    "::-internal-media-controls-overlay-cast-button_display": "xupis34",
    $$css: true
  }
};
const isDev = process.env.NODE_ENV === "development";
var _default = exports.default = {
  div: _a => {
    var _b = _a,
      {
        ref,
        style,
        styleProps,
        children
      } = _b,
      props = __objRest(_b, ["ref", "style", "styleProps", "children"]);
    var _a2, _b2, _c, _d;
    const {
      className,
      style: styleProp
    } = stylex.props(baseStyles.base, style);
    const visualClasses = isDev ? (((_d = (_c = (_b2 = (_a2 = className == null ? void 0 : className.split(" ")) == null ? void 0 : _a2.filter(c => c == null ? void 0 : c.includes("__"))) == null ? void 0 : _b2.filter(c => !(c == null ? void 0 : c.includes("baseStyles.base")))) == null ? void 0 : _c.map(c => c == null ? void 0 : c.replace(/^.*[.]/, "").trim())) == null ? void 0 : _d.join(" ")) + " " || "").trim() : void 0;
    return /* @__PURE__ */_react.default.createElement("div", __spreadProps(__spreadValues({
      ref
    }, props), {
      "data-id": visualClasses,
      className,
      style: __spreadValues(__spreadValues({}, styleProp), styleProps)
    }), children);
  },
  a: _c => {
    var _d = _c,
      {
        ref,
        style
      } = _d,
      props = __objRest(_d, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("a", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  video: _e => {
    var _f = _e,
      {
        ref,
        style,
        styleProps
      } = _f,
      props = __objRest(_f, ["ref", "style", "styleProps"]);
    const {
      className
    } = stylex.props(baseStyles.base, baseStyles.videoBase, style);
    return /* @__PURE__ */_react.default.createElement("video", __spreadProps(__spreadValues({
      playsInline: true,
      ref
    }, props), {
      style: styleProps,
      className
    }));
  },
  iframe: _g => {
    var _h = _g,
      {
        ref,
        style
      } = _h,
      props = __objRest(_h, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("iframe", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  canvas: _i => {
    var _j = _i,
      {
        ref,
        style
      } = _j,
      props = __objRest(_j, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("canvas", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  dialog: _k => {
    var _l = _k,
      {
        ref,
        style
      } = _l,
      props = __objRest(_l, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("dialog", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  label: _m => {
    var _n = _m,
      {
        ref,
        style
      } = _n,
      props = __objRest(_n, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("label", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  input: _o => {
    var _p = _o,
      {
        ref,
        style
      } = _p,
      props = __objRest(_p, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("input", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  output: _q => {
    var _r = _q,
      {
        ref,
        style
      } = _r,
      props = __objRest(_r, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("output", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  progress: _s => {
    var _t = _s,
      {
        ref,
        style
      } = _t,
      props = __objRest(_t, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("progress", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  img: _u => {
    var _v = _u,
      {
        ref,
        style
      } = _v,
      props = __objRest(_v, ["ref", "style"]);
    const {
      className,
      style: styleProp
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("img", __spreadProps(__spreadValues({
      ref
    }, props), {
      style: styleProp,
      className
    }));
  },
  form: _w => {
    var _x = _w,
      {
        ref,
        style
      } = _x,
      props = __objRest(_x, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("form", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  h2: _y => {
    var _z = _y,
      {
        ref,
        style
      } = _z,
      props = __objRest(_z, ["ref", "style"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("h2", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  ol: _A => {
    var _B = _A,
      {
        ref,
        style,
        type: _type
      } = _B,
      props = __objRest(_B, ["ref", "style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("ol", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  ul: _C => {
    var _D = _C,
      {
        ref,
        style,
        type: _type
      } = _D,
      props = __objRest(_D, ["ref", "style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("ul", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  },
  li: _E => {
    var _F = _E,
      {
        ref,
        style,
        type: _type
      } = _F,
      props = __objRest(_F, ["ref", "style", "type"]);
    const {
      className
    } = stylex.props(baseStyles.base, style);
    return /* @__PURE__ */_react.default.createElement("li", __spreadProps(__spreadValues({
      ref
    }, props), {
      className
    }));
  }
};