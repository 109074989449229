"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _compilerRuntime = require("react/compiler-runtime");
var _Html = _interopRequireDefault(require("./Html"));
var _react = _interopRequireWildcard(require("react"));
var _reactWindow = require("react-window");
var _reactVirtualizedAutoSizer = _interopRequireDefault(require("react-virtualized-auto-sizer"));
var _helpers = require("@/shared/helpers");
var stylex = _interopRequireWildcard(require("@stylexjs/stylex"));
var _ContextMenu = require("./ContextMenu");
var _Button = require("./Button");
var _Image = require("./Image");
var _Icon = require("./Icon");
var _reactResponsive = require("react-responsive");
var _react2 = require("@use-gesture/react");
var _useMobileDetection = require("@/shared/hooks/useMobileDetection");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) for (var prop of __getOwnPropSymbols(source)) {
    if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
  }
  return target;
};
const isAndroid = /android/i.test(navigator.userAgent);
const styles = {
  ellipsis: {
    "MessageList__styles.ellipsis": "MessageList__styles.ellipsis",
    fontSize: "xwsyq91",
    margin: "x1ghz6dp",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    $$css: true
  },
  wrapper: {
    "MessageList__styles.wrapper": "MessageList__styles.wrapper",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    height: "x5yr21d",
    width: "xh8yej3",
    $$css: true
  },
  virtualized: {
    "MessageList__styles.virtualized": "MessageList__styles.virtualized",
    minHeight: "xseoqlg",
    $$css: true
  },
  preventScroll: {
    "MessageList__styles.preventScroll": "MessageList__styles.preventScroll",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    pointerEvents: "x47corl",
    $$css: true
  },
  roomItem: {
    "MessageList__styles.roomItem": "MessageList__styles.roomItem",
    display: "x78zum5",
    minHeight: "x1us19tq",
    zIndex: "x1vjfegm",
    pointerEvents: "x71s49j",
    paddingLeft: "x1lqa7cf",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingRight: "xcicffo",
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    flexDirection: "x1q0g3np",
    position: "x1n2onr6",
    color: "x11jfisy",
    textDecoration: "x1hl2dhg",
    textDecorationColor: null,
    textDecorationLine: null,
    textDecorationStyle: null,
    textDecorationThickness: null,
    cursor: "x1ypdohk",
    alignItems: "x6s0dn4",
    fontSize: "x1j61zf2",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    ":active_backgroundColor": "x52vrxo",
    "@media (max-width: 1000px)_fontSize": "x1xnrtps",
    "@media (max-width: 1000px)_minHeight": "x15fgtqy",
    "@media (max-width: 1000px)_height": "x1ltrzbh",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "x11zpyrr xv9cu4j",
    $$css: true
  },
  roomItemWrapper: {
    "MessageList__styles.roomItemWrapper": "MessageList__styles.roomItemWrapper",
    position: "x1n2onr6",
    minHeight: "xseoqlg",
    height: "xwzfr38",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    "@media (max-width: 1000px)_minHeight": "x1azp1ia",
    "@media (max-width: 1000px)_height": "x1kzbrei",
    $$css: true
  },
  drawerMenu: {
    "MessageList__styles.drawerMenu": "MessageList__styles.drawerMenu",
    width: "xygnafs",
    height: "x5yr21d",
    background: "x1dq10d1",
    backgroundAttachment: null,
    backgroundClip: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundOrigin: null,
    backgroundPosition: null,
    backgroundPositionX: null,
    backgroundPositionY: null,
    backgroundRepeat: null,
    backgroundSize: null,
    color: "x11jfisy",
    transform: "xbf94vf",
    position: "x10l6tqk",
    right: "x3m8u43",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    transition: "x112o3c2",
    transitionBehavior: null,
    transitionDelay: null,
    transitionDuration: null,
    transitionProperty: null,
    transitionTimingFunction: null,
    paddingTop: "x889kno",
    paddingBottom: "x1a8lsjc",
    $$css: true
  },
  right: {
    "MessageList__styles.right": "MessageList__styles.right",
    display: "x78zum5",
    flexDirection: "xdt5ytf",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    paddingLeft: "x163pfp",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    $$css: true
  },
  upperRow: {
    "MessageList__styles.upperRow": "MessageList__styles.upperRow",
    display: "x78zum5",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    maxHeight: "x18wx58x",
    $$css: true
  },
  lowerRow: {
    "MessageList__styles.lowerRow": "MessageList__styles.lowerRow",
    display: "x78zum5",
    marginTop: "x1anpbxc",
    height: "x1qx5ct2",
    width: "xh8yej3",
    marginLeft: "x8x9d4c",
    marginInlineStart: null,
    marginInlineEnd: null,
    marginRight: "xack27t",
    $$css: true
  },
  members: {
    "MessageList__styles.members": "MessageList__styles.members",
    display: "x78zum5",
    marginLeft: "x1tpqehw",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  roomIcon: {
    "MessageList__styles.roomIcon": "MessageList__styles.roomIcon",
    padding: "x1717udv",
    paddingInline: null,
    paddingStart: null,
    paddingLeft: null,
    paddingEnd: null,
    paddingRight: null,
    paddingBlock: null,
    paddingTop: null,
    paddingBottom: null,
    margin: "x1bpp3o7",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: "xyo1k6t",
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    position: "x1n2onr6",
    width: "x100vrsf",
    height: "x1vqgdyp",
    minWidth: "x1fns5xo",
    "@media (max-width: 1000px)_width": "xxsk4lk",
    "@media (max-width: 1000px)_height": "xo2sn4m",
    "@media (max-width: 1000px)_minWidth": "x1xep6jk",
    $$css: true
  },
  roomIconImage: {
    "MessageList__styles.roomIconImage": "MessageList__styles.roomIconImage",
    height: "x5yr21d",
    width: "x14atkfc",
    $$css: true
  },
  inActive: {
    "MessageList__styles.inActive": "MessageList__styles.inActive",
    color: "x164e47y",
    $$css: true
  },
  latestMessage: {
    "MessageList__styles.latestMessage": "MessageList__styles.latestMessage",
    display: "x3nfvp2",
    justifyContent: "x1qughib",
    height: "x1qx5ct2",
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    margin: "x1k3vv4n",
    marginInline: null,
    marginInlineStart: null,
    marginLeft: null,
    marginInlineEnd: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    color: "x164e47y",
    maxWidth: "x193iq5w",
    width: "xh8yej3",
    $$css: true
  },
  latestMessageText: {
    "MessageList__styles.latestMessageText": "MessageList__styles.latestMessageText",
    display: "xt0psk2",
    maxWidth: "x256u9z",
    width: "xh8yej3",
    height: "x5yr21d",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    overflow: "xb3r6kr",
    overflowX: null,
    overflowY: null,
    textOverflow: "xlyipyv",
    whiteSpace: "xuxw1ft",
    paddingRight: "xy13l1i",
    paddingInlineStart: null,
    paddingInlineEnd: null,
    paddingLeft: "x1uhho1l",
    $$css: true
  },
  unread: {
    "MessageList__styles.unread": "MessageList__styles.unread",
    color: "x16cd2qt",
    fontWeight: "x1s688f",
    $$css: true
  },
  optionsDropdownWrapper: {
    "MessageList__styles.optionsDropdownWrapper": "MessageList__styles.optionsDropdownWrapper",
    display: "x78zum5",
    flex: "x98rzlu",
    flexGrow: null,
    flexShrink: null,
    flexBasis: null,
    justifyContent: "x13a6bvl",
    maxWidth: "xazcve0",
    width: "x1exxlbk",
    marginLeft: null,
    marginInlineStart: null,
    marginInlineEnd: null,
    position: "x10l6tqk",
    height: "x10w6t97",
    right: "x1gv0tr7",
    insetInlineStart: null,
    insetInlineEnd: null,
    top: "x13vifvy",
    bottom: "x1ey2m1c",
    margin: "x1bpp3o7",
    marginInline: null,
    marginRight: null,
    marginBlock: null,
    marginTop: null,
    marginBottom: null,
    alignItems: "x6s0dn4",
    $$css: true
  },
  roomOptions: {
    "MessageList__styles.roomOptions": "MessageList__styles.roomOptions",
    minHeight: "x1wxaq2x",
    borderWidth: "xc342km",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    minWidth: "x900493",
    borderRadius: "x16rqkct",
    borderStartStartRadius: null,
    borderStartEndRadius: null,
    borderEndStartRadius: null,
    borderEndEndRadius: null,
    borderTopLeftRadius: null,
    borderTopRightRadius: null,
    borderBottomLeftRadius: null,
    borderBottomRightRadius: null,
    opacity: "x1us6l5c",
    ":hover_opacity": "x1o7uuvo",
    $$css: true
  },
  odd: {
    "MessageList__styles.odd": "MessageList__styles.odd",
    backgroundColor: "x1hgespx",
    "@media (hover: hover) and (pointer: fine)_backgroundColor": "xv9cu4j x11zpyrr",
    $$css: true
  },
  member: {
    "MessageList__styles.member": "MessageList__styles.member",
    width: "xw4jnvo",
    minWidth: "xt4ypqs",
    height: "x1qx5ct2",
    borderWidth: "xmkeg23",
    borderInlineWidth: null,
    borderInlineStartWidth: null,
    borderLeftWidth: null,
    borderInlineEndWidth: null,
    borderRightWidth: null,
    borderBlockWidth: null,
    borderTopWidth: null,
    borderBottomWidth: null,
    borderStyle: "x1y0btm7",
    borderInlineStyle: null,
    borderInlineStartStyle: null,
    borderLeftStyle: null,
    borderInlineEndStyle: null,
    borderRightStyle: null,
    borderBlockStyle: null,
    borderTopStyle: null,
    borderBottomStyle: null,
    borderColor: "xntzcp6",
    borderInlineColor: null,
    borderInlineStartColor: null,
    borderLeftColor: null,
    borderInlineEndColor: null,
    borderRightColor: null,
    borderBlockColor: null,
    borderTopColor: null,
    borderBottomColor: null,
    marginLeft: "x18wv8x9",
    marginInlineStart: null,
    marginInlineEnd: null,
    $$css: true
  },
  onlineIcon: {
    "MessageList__styles.onlineIcon": "MessageList__styles.onlineIcon",
    position: "x10l6tqk",
    bottom: "x1o583il",
    right: "xzkxjte",
    insetInlineStart: null,
    insetInlineEnd: null,
    fontSize: "xfifm61",
    $$css: true
  },
  roomItemMovedToTheLeft: {
    "MessageList__styles.roomItemMovedToTheLeft": "MessageList__styles.roomItemMovedToTheLeft",
    transform: "x1gaodxz",
    $$css: true
  },
  drawerMenuOpen: {
    "MessageList__styles.drawerMenuOpen": "MessageList__styles.drawerMenuOpen",
    transform: "xulpuih",
    $$css: true
  }
};
const ListItem = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(81);
  const {
    onClick,
    title,
    content,
    unread,
    avatarUrl,
    memberAvatars,
    timestamp,
    inActive,
    contextMenu,
    odd,
    online,
    setPreventScroll,
    preventScroll,
    style
  } = t0;
  const [showContextMenu, setShowContextMenu] = (0, _react.useState)(false);
  const [hover, setHover] = (0, _react.useState)(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      query: "(hover: hover) and (pointer: fine)"
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const hasPointer = (0, _reactResponsive.useMediaQuery)(t1);
  const [drawerOpen, setDrawerOpen] = (0, _react.useState)(false);
  let t2;
  if ($[1] !== setPreventScroll) {
    t2 = () => {
      setDrawerOpen(true);
      if (setPreventScroll) {
        setPreventScroll(true);
      }
    };
    $[1] = setPreventScroll;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const openDrawer = t2;
  let t3;
  if ($[3] !== setPreventScroll) {
    t3 = () => {
      setDrawerOpen(false);
      if (setPreventScroll) {
        setPreventScroll(false);
      }
    };
    $[3] = setPreventScroll;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  const closeDrawer = t3;
  let t4;
  if ($[5] !== closeDrawer || $[6] !== contextMenu || $[7] !== hasPointer || $[8] !== openDrawer || $[9] !== preventScroll || $[10] !== setPreventScroll) {
    t4 = hasPointer ? _temp : t52 => {
      const {
        down,
        movement: t62
      } = t52;
      let [mx, my] = t62;
      if (isAndroid) {
        mx = mx * 7;
        my = my * 7;
      }
      if (!contextMenu || contextMenu.length === 0) {
        return;
      }
      if (mx == 0 && mx === my) {
        return;
      }
      if (!down) {
        if (setPreventScroll) {
          setPreventScroll(true);
        }
        if (mx < 0 && Math.abs(my) < (isAndroid ? 5 : 50)) {
          if (!preventScroll) {
            openDrawer();
          }
        } else {
          closeDrawer();
        }
      }
    };
    $[5] = closeDrawer;
    $[6] = contextMenu;
    $[7] = hasPointer;
    $[8] = openDrawer;
    $[9] = preventScroll;
    $[10] = setPreventScroll;
    $[11] = t4;
  } else {
    t4 = $[11];
  }
  let t5;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = {
      filterTaps: true
    };
    $[12] = t5;
  } else {
    t5 = $[12];
  }
  const bind = (0, _react2.useDrag)(t4, t5);
  const wrapperRef = (0, _react.useRef)(null);
  let t6;
  if ($[13] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = () => {
      setShowContextMenu(false);
    };
    $[13] = t6;
  } else {
    t6 = $[13];
  }
  const onClose = t6;
  let t7;
  if ($[14] !== showContextMenu) {
    t7 = e => {
      e.preventDefault();
      e.stopPropagation();
      setShowContextMenu(!showContextMenu);
    };
    $[14] = showContextMenu;
    $[15] = t7;
  } else {
    t7 = $[15];
  }
  let t8;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      name: "ellipsis horizontal",
      className: styles.ellipsis
    });
    $[16] = t8;
  } else {
    t8 = $[16];
  }
  let t9;
  if ($[17] !== t7) {
    t9 = /* @__PURE__ */_react.default.createElement(_Button.Button, {
      color: "blue",
      size: "xs",
      className: styles.roomOptions,
      onClick: t7
    }, t8);
    $[17] = t7;
    $[18] = t9;
  } else {
    t9 = $[18];
  }
  let t10;
  if ($[19] !== contextMenu) {
    t10 = contextMenu == null ? void 0 : contextMenu.map(_temp2);
    $[19] = contextMenu;
    $[20] = t10;
  } else {
    t10 = $[20];
  }
  let t11;
  if ($[21] !== showContextMenu || $[22] !== t10 || $[23] !== t9) {
    t11 = /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu, {
      onClose,
      fluid: true,
      position: "center",
      open: showContextMenu,
      className: styles.optionsDropdownWrapper,
      trigger: t9
    }, t10);
    $[21] = showContextMenu;
    $[22] = t10;
    $[23] = t9;
    $[24] = t11;
  } else {
    t11 = $[24];
  }
  const cMenu = t11;
  let t12;
  if ($[25] !== bind) {
    t12 = bind();
    $[25] = bind;
    $[26] = t12;
  } else {
    t12 = $[26];
  }
  const t13 = !!odd && styles.odd;
  const t14 = drawerOpen && styles.roomItemMovedToTheLeft;
  let t15;
  if ($[27] !== t13 || $[28] !== t14) {
    t15 = [styles.roomItem, t13, t14];
    $[27] = t13;
    $[28] = t14;
    $[29] = t15;
  } else {
    t15 = $[29];
  }
  const t16 = !drawerOpen && !showContextMenu && onClick || void 0;
  let t17;
  if ($[30] !== hasPointer) {
    t17 = hasPointer ? () => setHover(true) : void 0;
    $[30] = hasPointer;
    $[31] = t17;
  } else {
    t17 = $[31];
  }
  let t18;
  if ($[32] !== hasPointer) {
    t18 = hasPointer ? () => setHover(false) : void 0;
    $[32] = hasPointer;
    $[33] = t18;
  } else {
    t18 = $[33];
  }
  let t19;
  if ($[34] !== avatarUrl) {
    t19 = /* @__PURE__ */_react.default.createElement(_Image.Image, {
      circular: true,
      className: styles.roomIconImage,
      src: avatarUrl,
      loading: "lazy"
    });
    $[34] = avatarUrl;
    $[35] = t19;
  } else {
    t19 = $[35];
  }
  let t20;
  if ($[36] !== online) {
    t20 = online && /* @__PURE__ */_react.default.createElement(_Icon.Icon, {
      className: styles.onlineIcon,
      name: "circle",
      color: "green"
    });
    $[36] = online;
    $[37] = t20;
  } else {
    t20 = $[37];
  }
  let t21;
  if ($[38] !== t19 || $[39] !== t20) {
    t21 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.roomIcon
    }, t19, t20);
    $[38] = t19;
    $[39] = t20;
    $[40] = t21;
  } else {
    t21 = $[40];
  }
  const t22 = inActive && styles.inActive;
  const t23 = unread && styles.unread;
  let t24;
  if ($[41] !== t22 || $[42] !== t23) {
    t24 = [t22, t23];
    $[41] = t22;
    $[42] = t23;
    $[43] = t24;
  } else {
    t24 = $[43];
  }
  let t25;
  if ($[44] !== t24 || $[45] !== title) {
    t25 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.upperRow
    }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t24
    }, title));
    $[44] = t24;
    $[45] = title;
    $[46] = t25;
  } else {
    t25 = $[46];
  }
  const t26 = unread && styles.unread;
  let t27;
  if ($[47] !== t26) {
    t27 = [styles.latestMessage, t26];
    $[47] = t26;
    $[48] = t27;
  } else {
    t27 = $[48];
  }
  let t28;
  if ($[49] !== content) {
    t28 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.latestMessageText
    }, content);
    $[49] = content;
    $[50] = t28;
  } else {
    t28 = $[50];
  }
  let t29;
  if ($[51] !== timestamp) {
    t29 = timestamp && /* @__PURE__ */_react.default.createElement(_Html.default.div, null, (0, _helpers.getShorthandTime)(timestamp));
    $[51] = timestamp;
    $[52] = t29;
  } else {
    t29 = $[52];
  }
  let t30;
  if ($[53] !== t27 || $[54] !== t28 || $[55] !== t29) {
    t30 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t27
    }, t28, t29);
    $[53] = t27;
    $[54] = t28;
    $[55] = t29;
    $[56] = t30;
  } else {
    t30 = $[56];
  }
  let t31;
  if ($[57] !== memberAvatars) {
    t31 = memberAvatars && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.lowerRow
    }, /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.members
    }, memberAvatars == null ? void 0 : memberAvatars.slice(0, 3).map(_temp3), (memberAvatars == null ? void 0 : memberAvatars.length) > 3 && /* @__PURE__ */_react.default.createElement(_Html.default.div, null, "+", (memberAvatars == null ? void 0 : memberAvatars.length) - 3)));
    $[57] = memberAvatars;
    $[58] = t31;
  } else {
    t31 = $[58];
  }
  let t32;
  if ($[59] !== t25 || $[60] !== t30 || $[61] !== t31) {
    t32 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: styles.right
    }, t25, t30, t31);
    $[59] = t25;
    $[60] = t30;
    $[61] = t31;
    $[62] = t32;
  } else {
    t32 = $[62];
  }
  const t33 = hover && contextMenu && contextMenu.length > 0 && hasPointer && cMenu;
  let t34;
  if ($[63] !== t15 || $[64] !== t16 || $[65] !== t17 || $[66] !== t18 || $[67] !== t21 || $[68] !== t32 || $[69] !== t33) {
    t34 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t15,
      onClick: t16,
      onMouseEnter: t17,
      onMouseLeave: t18
    }, t21, t32, t33);
    $[63] = t15;
    $[64] = t16;
    $[65] = t17;
    $[66] = t18;
    $[67] = t21;
    $[68] = t32;
    $[69] = t33;
    $[70] = t34;
  } else {
    t34 = $[70];
  }
  let t35;
  if ($[71] !== cMenu || $[72] !== contextMenu || $[73] !== drawerOpen || $[74] !== hasPointer) {
    t35 = !hasPointer && /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: [styles.drawerMenu, drawerOpen && styles.drawerMenuOpen]
    }, contextMenu && contextMenu.length > 0 && cMenu);
    $[71] = cMenu;
    $[72] = contextMenu;
    $[73] = drawerOpen;
    $[74] = hasPointer;
    $[75] = t35;
  } else {
    t35 = $[75];
  }
  let t36;
  if ($[76] !== style || $[77] !== t12 || $[78] !== t34 || $[79] !== t35) {
    t36 = /* @__PURE__ */_react.default.createElement(_Html.default.div, __spreadProps(__spreadValues({}, t12), {
      ref: wrapperRef,
      style: styles.roomItemWrapper,
      styleProps: style
    }), t34, t35);
    $[76] = style;
    $[77] = t12;
    $[78] = t34;
    $[79] = t35;
    $[80] = t36;
  } else {
    t36 = $[80];
  }
  return t36;
});
const Row = (0, _react.memo)(t0 => {
  var _a;
  const $ = (0, _compilerRuntime.c)(10);
  let large;
  let props;
  let style;
  if ($[0] !== t0) {
    _a = t0, {
      style,
      large
    } = _a, props = __objRest(_a, ["style", "large"]);
    $[0] = t0;
    $[1] = large;
    $[2] = props;
    $[3] = style;
  } else {
    large = $[1];
    props = $[2];
    style = $[3];
  }
  const t1 = large ? 120 : 80;
  let t2;
  if ($[4] !== style || $[5] !== t1) {
    t2 = __spreadProps(__spreadValues({}, style), {
      height: t1
    });
    $[4] = style;
    $[5] = t1;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  let t3;
  if ($[7] !== props || $[8] !== t2) {
    t3 = /* @__PURE__ */_react.default.createElement(ListItem, __spreadProps(__spreadValues({}, props), {
      style: t2
    }));
    $[7] = props;
    $[8] = t2;
    $[9] = t3;
  } else {
    t3 = $[9];
  }
  return t3;
});
var _default = exports.default = (0, _react.memo)(t0 => {
  const $ = (0, _compilerRuntime.c)(29);
  const {
    ref,
    items,
    large
  } = t0;
  const {
    isMobile
  } = (0, _useMobileDetection.useIsMobile)();
  const [preventScroll, setPreventScroll] = (0, _react.useState)(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => () => {
      setPreventScroll(false);
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== items.length) {
    t2 = [items.length];
    $[1] = items.length;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  (0, _react.useEffect)(t1, t2);
  if (items.length < 10) {
    const t32 = preventScroll && styles.preventScroll;
    let t42;
    if ($[3] !== t32) {
      t42 = [styles.wrapper, t32];
      $[3] = t32;
      $[4] = t42;
    } else {
      t42 = $[4];
    }
    let t52;
    if ($[5] !== isMobile || $[6] !== items || $[7] !== large) {
      let t63;
      if ($[9] !== isMobile || $[10] !== large) {
        t63 = (item, index) => /* @__PURE__ */_react.default.createElement(Row, __spreadProps(__spreadValues({
          key: index,
          odd: index % 2 !== 0
        }, item), {
          large: isMobile || large
        }));
        $[9] = isMobile;
        $[10] = large;
        $[11] = t63;
      } else {
        t63 = $[11];
      }
      t52 = items.map(t63);
      $[5] = isMobile;
      $[6] = items;
      $[7] = large;
      $[8] = t52;
    } else {
      t52 = $[8];
    }
    let t62;
    if ($[12] !== t42 || $[13] !== t52) {
      t62 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
        style: t42
      }, t52);
      $[12] = t42;
      $[13] = t52;
      $[14] = t62;
    } else {
      t62 = $[14];
    }
    return t62;
  }
  let t3;
  if ($[15] !== isMobile || $[16] !== items || $[17] !== large) {
    t3 = t42 => {
      const {
        index: index_0,
        style
      } = t42;
      return /* @__PURE__ */_react.default.createElement(Row, __spreadProps(__spreadValues({}, items[index_0]), {
        style,
        odd: index_0 % 2 !== 0,
        large: isMobile || large
      }));
    };
    $[15] = isMobile;
    $[16] = items;
    $[17] = large;
    $[18] = t3;
  } else {
    t3 = $[18];
  }
  const DynamicRow = t3;
  const t4 = preventScroll && styles.preventScroll;
  let t5;
  if ($[19] !== t4) {
    t5 = [styles.wrapper, styles.virtualized, t4];
    $[19] = t4;
    $[20] = t5;
  } else {
    t5 = $[20];
  }
  let t6;
  if ($[21] !== DynamicRow || $[22] !== isMobile || $[23] !== items.length || $[24] !== large) {
    t6 = /* @__PURE__ */_react.default.createElement(_reactVirtualizedAutoSizer.default, null, t72 => {
      const {
        width,
        height
      } = t72;
      return /* @__PURE__ */_react.default.createElement(_reactWindow.FixedSizeList, {
        ref,
        width,
        height,
        itemCount: items.length,
        itemSize: isMobile || large ? 120 : 80
      }, DynamicRow);
    });
    $[21] = DynamicRow;
    $[22] = isMobile;
    $[23] = items.length;
    $[24] = large;
    $[25] = t6;
  } else {
    t6 = $[25];
  }
  let t7;
  if ($[26] !== t5 || $[27] !== t6) {
    t7 = /* @__PURE__ */_react.default.createElement(_Html.default.div, {
      style: t5
    }, t6);
    $[26] = t5;
    $[27] = t6;
    $[28] = t7;
  } else {
    t7 = $[28];
  }
  return t7;
});
function _temp() {}
function _temp2(t0, index) {
  const {
    icon,
    text,
    action
  } = t0;
  return /* @__PURE__ */_react.default.createElement(_ContextMenu.ContextMenu.Item, {
    key: index,
    text,
    icon,
    onClick: action
  });
}
function _temp3(m, index_0) {
  return /* @__PURE__ */_react.default.createElement(_Image.Image, {
    key: index_0,
    className: styles.member,
    circular: true,
    src: m,
    loading: "lazy"
  });
}